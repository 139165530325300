import styled from "styled-components";
import restaurants from "../data/restaurants";
import { Link } from "react-router-dom";
// import Grid from '@mui/material/Grid';

function RestaurantsFlexBox() {

    
    return (
        <>
        <GridContainerWrapperStyled>
            <div className = "restaurants_grid">
                <div className = "restaurants_grid_item">
                    <Link to = "restaurants/le-train-bleu" target = "_blank">
                        <img src = "/restaurants/01-le-train-bleu-restaurant.jpg" alt = "Le Train Bleu Restaurant cover pic"/>
                    </Link>   
                </div>
                <div className = "restaurants_grid_item">
                    <Link to = "/restaurants/shawarma-el-khebbez" target = "_blank">
                        <img src = "/restaurants/02-shawarma-el-khebbez-restaurant.jpg" alt = "Shawarma El Khebbez Restaurant cover pic"/>
                    </Link>
                </div>
                <div className = "restaurants_grid_item">
                    <Link to = "/restaurants/le-bleu" target = "_blank">
                        <img src = "/restaurants/03-le-bleu-restaurant.jpg" alt = "Le Bleu Restaurant cover pic"/>
                    </Link>
                </div>
                <div className = "restaurants_grid_item">
                    <Link to = "/restaurants/white-coffee-spot" target = "_blank">
                        <img src = "/restaurants/04-white-coffee-spot-restaurant.jpg" alt = "White Coffee Spot Restaurant cover pic"/>
                    </Link>
                </div>
                <div className = "restaurants_grid_item">
                <img src = "/restaurants/05-ergon-restaurant.jpg" alt = "Ergon Restaurant cover pic"/>
                </div>
                <div className = "restaurants_grid_item">
                    <img src = "/restaurants/06-kabab-al-deera-restaurant.jpg" alt = "Kabab Al Deera cover pic"/>
                </div>
                <div className = "restaurants_grid_item">
                    <img src = "/restaurants/07-baron-restaurant.jpg" alt = "Baron Restaurant cover pic"/>
                </div>
                <div className = "restaurants_grid_item">
                    <Link to = "/restaurants/five-am" target = "_blank">
                        <img src = "/restaurants/08-five-am-restaurant.jpg" alt = "Five AM Restaurant cover pic"/>
                    </Link>
                </div>
                <div className = "restaurants_grid_item">
                    <img src = "/restaurants/09-habra-restaurant.jpg" alt = "Habra Restaurant cover pic"/>
                </div>
                <div className = "restaurants_grid_item">
                    <img src = "/restaurants/11-lunchroom-restaurant.jpg" alt = "Lunchroom Restaurant cover pic"/>
                </div>
                <div className = "restaurants_grid_item">
                    <img src = "/restaurants/12-hana-restaurant.jpg" alt = "Hana Restaurant cover pic"/>
                </div>
            </div>
            </GridContainerWrapperStyled>
        </>
    )
}

const GridContainerWrapperStyled = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
`


export default RestaurantsFlexBox;