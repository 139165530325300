import {useEffect, useState} from "react";
import styled from "styled-components";
import { PageHeadingStyled } from "../RestaurantsCommonComps/FoodStyled";
import {Grid} from "@mui/material";

function BrandSek({}) {

    const [isDesktop, setIsDesktop] = useState(true);

    function updateScreenSize() {
        if(window.innerWidth <= 500) setIsDesktop(false);
        else setIsDesktop(true);
    }

    useEffect(() => {
        window.addEventListener("resize", updateScreenSize);

        return () => {
            window.removeEventListener("reisze", updateScreenSize);
        }
    })

    if(isDesktop) {
        return (
            <>
                <BrandContainerStyled>
                    <FlexBoxOneStyled>
                        <PageHeadingStyled>
                            The Brand
                        </PageHeadingStyled>
                        <ParagraphContainerStyled style = {{width: "50%"}}>
                        <p>
                            Shawarma El Khebbez selectively combines vintage and streen aesthetics. A grffitti inspired logo, vintage
                            off-white street walla nd warm colours that were mostly used in the 70's. The brand appeals to the majority of the masses while
                            triggering nostalgia (allowing customers to relate) which is used in marketing and promotional content in both visual and text format
                        </p>
                    </ParagraphContainerStyled>
                    </FlexBoxOneStyled>
                    <Grid container spacing = {1.5} rowSpacing = {1.5}>
                        <Grid item sm = {12}>
                            <GridImageContainerStyled>
                                <img src = "/sek-designs/grid_imgs/arrm-sek-brand-grid-1.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        <Grid item sm = {4}>
                            <GridImageContainerStyled>
                                <img src = "/sek-designs/grid_imgs/arrm-sek-brand-grid-2.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        <Grid item sm = {4}>
                            <GridImageContainerStyled>
                                <img src = "/sek-designs/grid_imgs/arrm-sek-brand-grid-3.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        <Grid item sm = {4}>
                            <GridImageContainerStyled>
                                <img src = "/sek-designs/grid_imgs/arrm-sek-brand-grid-4.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        <Grid item sm = {8}>
                            <GridImageContainerStyled>
                                <img src = "/sek-designs/grid_imgs/arrm-sek-brand-grid-5.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        <Grid item sm = {4}>
                            <GridFlexColumnBoxStyled>
                                <GridImageContainerStyled>
                                    <img style = {{height: "100%"}}src = "/sek-designs/grid_imgs/arrm-sek-brand-grid-6.jpg"/>
                                </GridImageContainerStyled>
                                <GridImageContainerStyled>
                                    <img src = "/sek-designs/grid_imgs/arrm-sek-brand-grid-7.jpg"/>
                                </GridImageContainerStyled>
                            </GridFlexColumnBoxStyled>
                        </Grid>
                        <Grid item sm = {4}>
                            <GridImageContainerStyled>
                                <img src = "/sek-designs/grid_imgs/arrm-sek-brand-grid-8.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        <Grid item sm = {4}>
                            <GridImageContainerStyled>
                                <img src = "/sek-designs/grid_imgs/arrm-sek-brand-grid-9.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        <Grid item sm = {4}>
                            <GridImageContainerStyled>
                                <img src = "/sek-designs/grid_imgs/arrm-sek-brand-grid-10.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                    </Grid>
                </BrandContainerStyled>
            </>
        )
    }

    if(!isDesktop) {
        return (
            <>
                <BrandContainerMobileStyled>
                    <PageHeadingStyled>
                        The Brand
                    </PageHeadingStyled>
                    <Grid container spacing = {1.5} rowSpacing = {1.5}>
                        <Grid item xs = {12}>
                            <GridImageContainerStyled>
                                <img src = "/sek-designs/grid_imgs/arrm-sek-brand-grid-1.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        <Grid item xs = {6}>
                            <GridImageContainerStyled>
                                <img src = "/sek-designs/grid_imgs/arrm-sek-brand-grid-2.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        <Grid item xs = {6}>
                            <GridImageContainerStyled>
                                <img src = "/sek-designs/grid_imgs/arrm-sek-brand-grid-3.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        <Grid item xs = {6}>
                            <GridImageContainerStyled>
                                <img src = "/sek-designs/grid_imgs/arrm-sek-brand-grid-4.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        <Grid item xs = {6}>
                            <GridImageContainerStyled>
                                <img src = "/sek-designs/grid_imgs/arrm-sek-brand-grid-6.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        <Grid item xs = {6}>
                            <GridImageContainerStyled>
                                <img src = "/sek-designs/grid_imgs/arrm-sek-brand-grid-7.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        <Grid item xs = {6}>
                            <GridImageContainerStyled>
                                <img src = "/sek-designs/grid_imgs/arrm-sek-brand-grid-10.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        <Grid item xs = {12}>
                            <GridImageContainerStyled>
                                <img src = "/sek-designs/grid_imgs/arrm-sek-brand-grid-5.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        <Grid item xs = {6}>
                            <GridImageContainerStyled>
                                <img src = "/sek-designs/grid_imgs/arrm-sek-brand-grid-8.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        <Grid item xs = {6}>
                            <GridImageContainerStyled>
                                <img src = "/sek-designs/grid_imgs/arrm-sek-brand-grid-9.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                    </Grid>
                </BrandContainerMobileStyled>
            </>
        )
    }
}

const BrandContainerStyled = styled.div`
    position: relative;
    display: flex;
    padding: 0px 150px;
`

const FlexBoxOneStyled = styled.div`
    position: relative;
    width: 50%;
    margin-top: 70px;
`

const ParagraphContainerStyled = styled.div`
    position: relative;

    p {
        font-size: 18px;
    }
`

const GridFlexColumnBoxStyled = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 10px;
`

const GridImageContainerStyled = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    /* box-shadow: 4px 4px 10px grey;
    border: 3px solid rgba(128, 128, 128, 0.4); */
    img {
        width: 100%;
        height: 100%;
    }
`

const BrandContainerMobileStyled = styled.div`
    position: relative;
    padding: 0px 30px;
`

export default BrandSek;;