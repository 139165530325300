
// --------------------------------------------- AL RAYYAN MAINPAGE LINK -----------------------------//
let navLinks = [
    {
        name: "Restaurants",
        link: "/restaurants",
        section_id: "#1",
    },

    {
        name: "About",
        link: "/about",
        section_id: "#2",
    },

    {
        name: "Catering",
        link: "/catering",
        section_id: "#3",
    },

    {
        name: "Contact",
        link: "/contact",
        section_id: "#4",
    },
]

// --------------------------------------------- WHITE COFFEE SPOT -----------------------------//
let navLinksWCS = [
    {
        elementId: "1",
        refNo: "#category_1",
        category: "About",
        className: "category_1",
    },

    {
        elementId: "2",
        refNo: "#category_2",
        category: "Concept",
        className: "category_2",
    },

    {
        elementId: "3",
        refNo: "#category_3",
        category: "Food",
        className: "category_3",
    },

    {
        elementId: "4",
        refNo: "#category_4",
        category: "Gallery",
        className: "category_4"
    },

    {
        elementId: "5",
        refNo: "#category_5",
        category: "Contact",
        className: "category_5",
    },

    
]

export {
    navLinks,
    navLinksWCS
}