import { MobileBurgerContainerStyled } from "../styles";

function MobileBurgerContainer({handleMobileBurger}) {
    return (
        <>
            <MobileBurgerContainerStyled onClick = {() => handleMobileBurger(true)}>
                <img src = "/burger icon.png" alt = "burger icon ARRM"/>
            </MobileBurgerContainerStyled>
        </>
    )
}

export default MobileBurgerContainer;