import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import { MainContainer, About, Concept, Food, Gallery, Contact } from "../components/WcsRes";
import { navLinksWCS } from "../data/navLinks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faBars, faXmark} from "@fortawesome/free-solid-svg-icons";
import $ from "jquery";

function WhiteCoffeeSpot () {

    // const navBarRef = useRef(null);
    const mainContainerRef = useRef(null);
    const [isMobile, setIsMobile] = useState(false);


    // useEffect(() => {

    //     const sections = document.querySelectorAll("section");
    //     const navBar = document.querySelector("#nav_bar");
    //     const navLi = document.querySelectorAll(".wcs_navBar li");


    //     const handleScroll = event => {

    //         var currentScroll = window.pageYOffset;
    
    //         // ---------------------------------------------------------------------
    //         //make nav bar fixed based on position of main container
    //         // if(navBar.getBoundingClientRect().top < 0) {
    //         //     console.log("trigd");
    //         //     navBar.style.position = "fixed";
    //         //     navBar.style.inset = "0px";
    //         //     navBar.style.marginTop = "0px";
    //         //     navBar.style.backgroundColor = "blue";
    //         //     // navBar.style.top = "50px";
    //         // }
    //         // --------------------------------------------------------------------

    //         if(currentScroll >= navBar.getBoundingClientRect().top +300) {
    //             $('.wcs_navBar').css({
    //                 position: 'fixed',
    //                 inset: "0px",
    //                 marginTop: "0px",
    //                 top: '0',
    //                 left: '0',
    //                 backgroundColor: "white",
    //             })
    //         } else {
    //             $('.wcs_navBar').css({
    //                 position: 'relative',
    //                 backgroundColor: "transparent",
    //                 marginTop: "-40px",
    //             })
    //         }
    //         var current = "";
    //         sections.forEach((section) => {
    //             const sectionTop = section.offsetTop;
    //             console.log("monitoring sections");
    //             if(window.pageYOffset >= sectionTop - 60) {
    //                 console.log("the current active section is: ", current )
    //                 current = section.getAttribute("id");
    //             }
    //         })
    //         navLi.forEach((li) => {
    //             li.classList.remove("active");
    //             if(li.classList.contains(current)) {
    //                 //add colour
    //                 li.classList.add("active");
    //             }
                
    //         })
    //     }

    //     window.addEventListener("scroll", handleScroll);
    //     // window.addEventListener("resize", updateScreenSize);

    //     return () => {
    //         window.removeEventListener("scroll", handleScroll )
    //         // window.removeEventListener("resize", updateScreenSize);
    //     }
    // }, [])

    return (
        <>
            <div>
            <MainContainer ref = {mainContainerRef}/>
             {/* <nav className="wcs_navBar" id = "nav_bar">
                        {
                            navLinksWCS.map(({elementId, refNo, category,  className}) => {
                                return (
                                    <li id = {elementId} className= {className }>
                                        <a href = {refNo}>{category}</a>
                                    </li>
                                )
                            })
                        }
            </nav> */}
            <section id = "category_1">
                <About/>
            </section>
            <section id = "category_2">
                <Concept/>
            </section>
                <section id = "category_3">
                    <Food/>
                </section>
            <categoryStyled>
                <section id = "category_4">
                    <Gallery/>
                </section>
            </categoryStyled>
            <section id = "category_5">
                <Contact/>
            </section>
            </div>
        </>
    )
}

// const CategoryStyled = styled.div`
//     position: relative;
//     width: 100%;
    
// `

// const BurgerContainerStyled = styled.div`
//     position: absolute;
//     inset: 0;
//     width: 20px;
//     height: 20px;
//     background-color: orange;
//     padding: 5px;
// `

// const BurgerIconStyled = styled.div`
//     position: relative;
//     font-size: 20px;

// `

export default WhiteCoffeeSpot;