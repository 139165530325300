import styled from "styled-components";

function WhoWeAreContainer({}) {
    return (
        <>
            <WhoWeAreContainerStyled>
                <h1>Who We Are</h1>
                <TestContainerStyled>
                <p>Al Rayyan Restaurant Management is a new company, established in 2014 by Sheikh Jassim Bin Khalid Bin Hamad Bin Abdullah Al-Thani and Al Jazira Group.</p>
                <p>Sheikh Jassim is a member of the ruling royal family who was instrumental in establishing the Hilton hotel chain in Doha and is one of the major investors in the new 'Festival City' where the IKEA franchise is located.</p>
                <p>The Al Jazira Company was founded in 1981 as an automotive parts and service outlet. Since then, it has branched out into real estate, investment, construction and logistics and holds several awards.</p>
                </TestContainerStyled>
            </WhoWeAreContainerStyled>
            <WhoWeAreContainerStyled style = {{marginTop: "146px"}}> 
                <h1 style = {{marginBottom: "29px"}}>Our Philosophy</h1>
                <TestContainerStyled>
                <p>We thrive in crating our own brands, as well as bringing the world's leading and most successful franchises to new markets and new customers in the GCC, taking them to new levels of success.</p>
                </TestContainerStyled>
            </WhoWeAreContainerStyled>
            <WhoWeAreContainerLastStyled style = {{marginTop: "172px"}}>
                <h1 style = {{padding: "0px 20px"}}>What We stand for</h1>
                <TestContainerStyled>
                <p>Our philosophy is to use total business integrity in everything we do.</p>
                </TestContainerStyled>
            </WhoWeAreContainerLastStyled>
        </>
    )
}

const WhoWeAreContainerStyled = styled.div`
    position: relative;
    text-align: center;
    

    h1 {
        font-size: 40px;
        font-weight: bold;
        margin-bottom: 22px;
    }

    p {
        
        font-size: 22px;
    }
`

const TestContainerStyled = styled.div`
    position: relative;
    width: 68%;
    margin: auto;
    text-align: left;
`

const WhoWeAreContainerLastStyled = styled.div`
    position: relative;
    text-align: center;

    h1 {
        font-size: 40px;
        font-weight: bold;
    }

    p {
        
        font-size: 22px;
    }

    @media(max-width: 420px) {
        h1 {
            font-size: 30px;
        }
       }
`

export default WhoWeAreContainer;