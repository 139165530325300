import styled from "styled-components";
import { MainContainerStyled, SectionHeadingStyled, ParagraphStyled } from "../RestaurantsCommonComps/AboutStyled";


function About({}) {
    return (
        <>
            <MainContainerStyled>
                <SectionHeadingStyled>
                    About
                </SectionHeadingStyled>
                <ParagraphStyled>
                    <p>Carefully crafted, White Coffee Spot conept aims to take the speciality
                        coffee experience in doha to a whole new level, with its minimalistic bright
                        interior, stylish communication, artistic atmosphere, upbeat music and
                        eloquent menu items
                    </p>
                </ParagraphStyled>
            </MainContainerStyled>
        </>
    )
}


export default About;