import styled from "styled-components";

const MainContainerStyled = styled.div`
    position: relative;
    width:100%;
    padding-left: 150px;
    padding-right: 150px;
    margin-bottom: 50px;

    @media(max-width: 800px) {
        padding-left: 80px;
        padding-right: 80px;
    }
    @media(max-width: 500px) {
        padding-left: 30px;
        padding-right: 30px;
    }
`
const SectionHeadingStyled = styled.div`
    position: relative;
    
    font-weight: bold;
    font-size: 20px;
    padding-top: 60px;

    @media(max-width: 800px) {
        padding-top: 30px;
    }
`

const ParagraphStyled = styled.div`
    position: relative;
    width: 50%;
    font-size: 18px;

    @media (max-width: 600px) {
        width: 80%;
    }
`

export {
    MainContainerStyled,
    SectionHeadingStyled,
    ParagraphStyled,
}