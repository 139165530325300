import styled from "styled-components";
import {useEffect, useRef, useState} from "react";
import { AboutSek, BrandSek, ConceptSek, ContactSek, FoodSek, HeaderSek } from "../components/sekRes";

function ShawarmaElKhebbez({}) {

    const mainContainerRef = useRef(null);
   
    return (
        <>
            <div>
                <HeaderSek ref = {mainContainerRef}/>
                <section id = "category_1">
                    <AboutSek/>
                </section>
                <section id = "category_2">
                    <ConceptSek/>
                </section>
                <section id = "category_3">
                    <FoodSek/>
                </section>
                <section id = "category_4">
                    <BrandSek/>
                </section>
                <section id = "category_5">
                    <ContactSek/>
                </section>
            </div>
        </>
    )
}

export default ShawarmaElKhebbez;