import styled from "styled-components";
import {useState, useEffect} from "react";

function ConceptFive({}) {

    const [isDesktop, setIsDesktop] = useState(true);

    function updateScreenSize() {
        if(window.innerWidth <= 500) setIsDesktop(false);
        else setIsDesktop(true);
        console.log(isDesktop);
    }

    useEffect(() => {
        window.addEventListener("resize", updateScreenSize);

        return () => {
            window.removeEventListener("resize", updateScreenSize);
        }
    })

    if(isDesktop) {
        return (
            <>
                <MainContainerStyled>
                    <ImageContainerStyled>
                        <img src = "/fiveAM-designs/backgroundImgs/arrm-five-cover-2.jpg"/>
                    </ImageContainerStyled>
                    <TextContainerStyled>
                        <PageHeadingStyled>
                            Concept
                        </PageHeadingStyled>
                        <ParagraphStyled>
                            <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor 
                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud 
                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
                                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum 
                                    dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non 
                                    proident, sunt in culpa qui officia deserunt mollit anim id est laborum
                            </p>
                        </ParagraphStyled>
                    </TextContainerStyled>
                </MainContainerStyled>
            </>
        )
    }

    if(!isDesktop) {
        return (
            <>
                <MainContainerStyled>
                    <ImageContainerMobileStyled>
                        <img src = "/fiveAM-designs/phone_backgroundImgs/arrm-five-cover-phone-2.jpg"/>
                    </ImageContainerMobileStyled>
                    <TextContainerStyled>
                        <PageHeadingStyled>
                            Concept
                        </PageHeadingStyled>
                        <ParagraphStyled>
                            <p>
                                Located in Doha Festival City - Luxury Area and by the VIP entrace, Le Bleu sends a
                                welcoming message to culinary enthusiasts to enjoy a swift and refined form of dining.
                            </p>
                        </ParagraphStyled>
                    </TextContainerStyled>
                </MainContainerStyled>
            </>
        )
    }
}

const MainContainerStyled = styled.div`
    position: relative;
    width: 100%;
    aspect-ratio: 16/9;
`

const ImageContainerStyled = styled.div`
    position: absolute;
    inset: 0;
    width: 100%;

    img {
        position: absolute; 
        width: 100%;
        aspect-ratio: 16/9;
    }
`

const ImageContainerMobileStyled = styled.div`
position: relative;
width: 100%;

img {
   
    width: 100%;
}
`

const TextContainerStyled = styled.div`
    position: absolute;
    inset:0;
    width: 25%;
    color: #F1EFD9;
    padding-left: 150px;
    margin-top: 50px;
    @media(max-width: 800px) {
        padding-left: 80px;
        width: 80%;
    }

    @media(max-width: 500px) {
        display: block;
        padding: 20px 30px;
        width: 68%;
    }

`

const PageHeadingStyled = styled.div`
     position: relative;
    font-weight: bold;
    font-size: 20px;


    @media (max-width: 600px) {  
    }
`

const ParagraphStyled = styled.div`
    position: relative;
    font-size: 18px;

    @media (max-width: 600px) {
    }
`

export default ConceptFive;