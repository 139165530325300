import styled from "styled-components";
import {useEffect, useState} from "react";
import {Grid} from '@mui/material';
import CloseIcon from "@mui/icons-material/Close"
import { MainContainerStyled, SectionHeadingStyled, ParagraphStyled } from "../RestaurantsCommonComps/AboutStyled";


function Gallery({}) {

    const [isDesktop, setIsDesktop] = useState(true);
    const [isFullImage, setIsFullImage] = useState(false);
    const [fullImage, setFullImage] = useState("");

    function updateScreenSize() {
        if(window.innerWidth <= 1110) setIsDesktop(false);
        else setIsDesktop(true);
        console.log(isDesktop);
    }

    useEffect(() => {
        window.addEventListener("resize", updateScreenSize);

        return () => {
            window.removeEventListener("resize", updateScreenSize);
        }
    })

    function showImage(id) {
        
        setIsFullImage(true);

        switch(id) {
            case "1":
                setFullImage("/wcs-designs/wcs-designs-grid-full/wcs_grid_image_1.jpg");
            break;

            case "2":
                setFullImage("/wcs-designs/wcs-designs-grid-full/wcs_grid_image_2.jpg");
            break;

            case "3":
                setFullImage("/wcs-designs/wcs-designs-grid-full/wcs_grid_image_3.jpg");
            break;

            case "4":
                setFullImage("/wcs-designs/wcs-designs-grid-full/wcs_grid_image_4.jpg");
            break;

            case "5":
                setFullImage("/wcs-designs/wcs-designs-grid-full/wcs_grid_image_5.jpg");
            break;

            case "6":
                setFullImage("/wcs-designs/wcs-designs-grid-full/wcs_grid_image_6.jpg");
            break;

            case "7":
                setFullImage("/wcs-designs/wcs-designs-grid-full/wcs_grid_image_7.jpg");
            break;

            case "8":
                setFullImage("/wcs-designs/wcs-designs-grid-full/wcs_grid_image_8.jpg");
            break;

            case "9":
                setFullImage("/wcs-designs/wcs-designs-grid-full/wcs_grid_image_9.jpg");
            break;

            case "false":
                setIsFullImage(false);
            break;

        }
    }
    if(isDesktop) {
        return (
            <>
                {isFullImage && <FullImageContainerStyled>
                    <CloseIcon fontSize = "large" className = "close_full_image" onClick = {() => showImage("false")} />
                    <FullImageWrapperStyled>
                        <img src = {fullImage}/>
                    </FullImageWrapperStyled>
                </FullImageContainerStyled>}
                <MainContainerStyled>
                    <SectionHeadingStyled>
                            Gallery
                    </SectionHeadingStyled>
                    <ParagraphStyled>
                            <p>
                                ART GALLERY, WITH ATTITUDE.
                            </p>
                            <p>
                                Aimed a standout experience to the customers the minute they step into the
                                coffee spot.
                            </p>
                            <p>
                                This is done through modern minimalist white interior design and decor, upbeat music and
                                welcomig atmosphere.
                            </p>
                    </ParagraphStyled>
                    <GridCenterStyled>
                    <div className = "wcs_wrapper_grid">
                        <div className = "wcs_wrapper_grid_item wcs_grid_box1">
                            <ImageContainerStyled>
                                <img src = "/wcs-designs/wcs-designs-grid/wcs_grid_image_1.jpg" onClick = {() => showImage("1")}/>
                            </ImageContainerStyled>
                        </div>
                        <div className = "wcs_wrapper_grid_item wcs_grid_box2">
                            <ImageContainerStyled>
                                <img  src = "/wcs-designs/wcs-designs-grid/wcs_grid_image_2.jpg" onClick = {() => showImage("2")}/>
                            </ImageContainerStyled>
                        </div>
                        <div className = "wcs_wrapper_grid_item wcs_grid_box3">
                            <ImageContainerStyled>
                                <img  src = "/wcs-designs/wcs-designs-grid/wcs_grid_image_3.jpg" onClick = {() => showImage("3")}/>
                            </ImageContainerStyled>
                        </div>
                        <div className = "wcs_wrapper_grid_item wcs_grid_box4">
                            <ImageContainerStyled>
                                <img  src = "/wcs-designs/wcs-designs-grid/wcs_grid_image_4.jpg" onClick = {() => showImage("4")}/>
                            </ImageContainerStyled>
                        </div>
                        <div className = "wcs_wrapper_grid_item wcs_grid_box5">
                            <ImageContainerStyled>
                                <img  src = "/wcs-designs/wcs-designs-grid/wcs_grid_image_5.jpg" onClick = {() => showImage("5")}/>
                            </ImageContainerStyled>
                        </div>
                        <div className = "wcs_wrapper_grid_item wcs_grid_box6">
                            <ImageContainerStyled>
                                <img src = "/wcs-designs/wcs-designs-grid/wcs_grid_image_6.jpg" onClick = {() => showImage("6")}/>
                            </ImageContainerStyled>
                        </div>
                        <div className = "wcs_wrapper_grid_item wcs_grid_box7">
                            <ImageContainerStyled>
                                <img  src = "/wcs-designs/wcs-designs-grid/wcs_grid_image_7.jpg" onClick = {() => showImage("7")}/>
                            </ImageContainerStyled>
                        </div>
                        <div className = "wcs_wrapper_grid_item wcs_grid_box8">
                            <ImageContainerStyled>
                                <img  src = "/wcs-designs/wcs-designs-grid/wcs_grid_image_8.jpg" onClick = {() => showImage("8")}/>
                            </ImageContainerStyled>
                        </div>
                        <div className = "wcs_wrapper_grid_item wcs_grid_box9">
                        <ImageContainerStyled>
                                <img  src = "/wcs-designs/wcs-designs-grid/wcs_grid_image_9.jpg" onClick = {() => showImage("9")}/>
                        </ImageContainerStyled>     
                        </div>
                    </div>
                    </GridCenterStyled>
                    <GridCenterStyled> 
                    </GridCenterStyled>
                </MainContainerStyled>
            </>
        )
    }

    if(!isDesktop) {
        return (
            <>
                {isFullImage && <FullImageContainerStyled>
                    <CloseIcon fontSize = "large" className = "close_full_image" onClick = {() => showImage("false")} />
                    <FullImageWrapperStyled>
                        <img src = {fullImage}/>
                    </FullImageWrapperStyled>
                </FullImageContainerStyled>}
                <MainContainerStyled>
                    <SectionHeadingStyled>
                        Gallery
                    </SectionHeadingStyled>
                    <ParagraphStyled>
                        <p>
                            ART GALLERY, WITH ATTITUDE.
                        </p>
                        <p>
                            Aimed a standout experience to the customers the minute they step into the
                            coffee spot.
                        </p>
                        <p>
                            This is done through modern minimalist white interior design and decor, upbeat music and
                            welcomig atmosphere.
                        </p>
                    </ParagraphStyled>
                    <GridCenterContainerStyled>
                        <GridCenterStyled>
                            <Grid container spacing={2}>
                                <Grid item xs={6} style = {{width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                                    <img style = {{width: "100%", height: ""}} src = "/wcs-designs/wcs-designs-grid/wcs_grid_image_1.jpg" onClick = {() => showImage("1")}/>
                                    <img style = {{width: "100%", height: ""}} src = "/wcs-designs/wcs-designs-grid/wcs_grid_image_7.jpg" onClick = {() => showImage("7")}/>
                                </Grid>
                                <Grid item xs={6} >
                                    <img style = {{width: "100%", height: "100%"}}  src = "/wcs-designs/wcs-designs-grid/wcs_grid_image_2.jpg" onClick = {() => showImage("2")}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <img style = {{width: "100%", height: "auto"}} src = "/wcs-designs/wcs-designs-grid/wcs_grid_image_4.jpg" onClick = {() => showImage("4")}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <img style = {{width: "100%", height: "auto"}} src = "/wcs-designs/wcs-designs-grid/wcs_grid_image_8.jpg" onClick = {() => showImage("8")}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <img style = {{width: "100%", height: "auto"}} src = "/wcs-designs/wcs-designs-grid/wcs_grid_image_3.jpg" onClick = {() => showImage("3")}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <img style = {{width: "100%", height: "auto"}} src = "/wcs-designs/wcs-designs-grid/wcs_grid_image_5.jpg" onClick = {() => showImage("5")}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <img style = {{width: "100%", height: "auto"}} src = "/wcs-designs/wcs-designs-grid/wcs_grid_image_6.jpg" onClick = {() => showImage("6")}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <img style = {{width: "100%", height: "auto"}} src = "/wcs-designs/wcs-designs-grid/wcs_grid_image_9.jpg"onClick = {() => showImage("9")}/>
                                </Grid>
                            </Grid>
                        </GridCenterStyled>
                    </GridCenterContainerStyled>
                </MainContainerStyled>
            </>
        )
    }
}

const FullImageContainerStyled = styled.div`
    position: fixed;
    display: flex;
    background-color: black;
    justify-content: center;
    align-items: center;
    z-index: 1;
    inset: 0;
    width: 100%;
`

const FullImageWrapperStyled = styled.div`
    position: relative;
   display:flex;
   justify-content: center;
    width: 40%;
    
   img {
        width: 100%;
        max-width: 440px;
    }

    @media(max-width: 500px) {
        width: 80%;
    }
`

const ImageContainerStyled = styled.div`
    
`
const GridCenterStyled = styled.div`
    
    display: flex; 
   
    width: 100%;

    @media(max-width: 500px) {
        margin-left: 0px;
       
    }
`

const GridCenterContainerStyled = styled.div`
    position: relative;

    @media(max-width: 500px) {
        padding: 0px 30px;
    }
`





export default Gallery;