import styled from "styled-components";
import { RestaurantsPageStyled, MobileMainContainerStyled,  } from "../styles";
import { MobileNavBarContainer, WhoWeAreContainer, MobileBurgerContainer, LogoContainer } from "../components";
import NavBar from '../components/NavBar';
import {useState, useEffect} from "react";


function About({}) {
    let [isDesktop, setIsDesktop] = useState(true);
    let [isMobileNavBar, setIsMobileNavBar] = useState(false);

    //track screen size for making a responsive page on mobile and desktop
    function updateScreenSize() {
        if(window.innerWidth <= 420) setIsDesktop(false);
        else setIsDesktop(true);
    }

    //activate the nav bar on a mobile screen
    function handleMobileBurger(navBarController) {
        if(navBarController == true) setIsMobileNavBar(true);
        else setIsMobileNavBar(false);
    }

    useEffect(() => {
        window.addEventListener("resize", updateScreenSize);

        return () => {
            window.removeEventListener("resize", updateScreenSize);
        }
    }, [])

    if(window.innerWidth > 420) {
        return (
            <>
                <RestaurantsPageStyled>
                    <WhoWeAreContainer/>
                </RestaurantsPageStyled>
            </>
        )
    }

if(window.innerWidth <= 420) {
    return (
        <>
           <RestaurantsPageStyled>
                <WhoWeAreContainer/>
            </RestaurantsPageStyled>
        </>
    )
}
}

// const RestaurantsPageHeading = styled.div`
//     position: relative;
//     display: block;
//     text-align: center;

    
//     span {
//         font-weight: bold;
//         font-size: 14px;
//     }
// `

export default About;