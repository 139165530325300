import styled from "styled-components";
import {useState, useEffect} from "react";
import { MainContainerStyled, ImageContainerStyled, ImageContainerMobileStyled, TextContainerStyled, PageHeadingStyled, ParagraphStyled, ButtonContainerStyled } from "../RestaurantsCommonComps/FoodStyled";

function Food({}) {
    
    const [isDesktop, setIsDesktop] = useState(true);

    function updateScreenSize() {
        if(window.innerWidth <= 500) setIsDesktop(false);
        else setIsDesktop(true);
    }

    useEffect(() => {
        window.addEventListener("resize", updateScreenSize);

        return () => {
            window.removeEventListener("resize", updateScreenSize);
        }
    })

    if(isDesktop) {
        return (
            <>
                <MainContainerStyled>
                    <ImageContainerStyled>
                        <img src = "/wcs-designs/backgroundImgs/arrm-wcs-cover-3.jpg" alt = "food section background" />
                    </ImageContainerStyled>
                    <TextContainerStyled>
                        <PageHeadingStyled>
                            Food
                        </PageHeadingStyled>
                        <ParagraphStyled>
                            <p>
                                The food menu celebrates our pastry foundatiosn and European techniques with simple fresh
                                ingredients that stay true and hubmle to the classics. <br/>
                                It houses a takeaway bakery, pastry and refined confectionery boutique that pair
                                extremely well with speciality coffees.
                                Comfortable food, small plates each showcasing individual sweet and savoury & bakery driven
                                teqniques.
                            </p>
                        </ParagraphStyled>
                        <ButtonContainerStyled>
                            <a href = "https://lovelysunnyday.com/restaurants/menus/white-coffee-spot" target="_blank"> 
                                <span>view menu</span>
                            </a>
                        </ButtonContainerStyled>
                    </TextContainerStyled>
                </MainContainerStyled>
            </>
        )
    }

    if(!isDesktop) {
        return (
            <>
                <MainContainerStyled>
                    <ImageContainerMobileStyled>
                        <img src = "/wcs-designs/phone_backgroundsImgs/wcs_food.jpg" />
                    </ImageContainerMobileStyled>
                    <TextContainerStyled>
                        <PageHeadingStyled>
                            Food
                        </PageHeadingStyled>
                        <ParagraphStyled>
                            <p>
                                The food menu celebrates our pastry foundatiosn and European techniques with simple fresh
                                ingredients that stay true and hubmle to the classics. 
                                <br/>
                                It houses a takeaway bakery, pastry and refined confectionery boutique that pair
                                extremely well with speciality coffees.
                                Comfortable food, small plates each showcasing individual sweet and savoury & bakery driven
                                teqniques.
                            </p>
                        </ParagraphStyled>
                        <ButtonContainerStyled>
                            <a href = "https://lovelysunnyday.com/restaurants/menus/white-coffee-spot" target="_blank"> 
                                <span>view menu</span>
                            </a>
                        </ButtonContainerStyled>
                    </TextContainerStyled>
                </MainContainerStyled>
            </>
        )
    }
}



export default Food;

