import './App.css';
import {BrowserRouter as Router, Routes as Switch, Route} from "react-router-dom";
import {About, Contact, Home, Restaurants, Catering, WhiteCoffeeSpot, ShawarmaElKhebbez, LeBleu, FiveAM, LTB} from "./pages";
import NavBar from './components/NavBar';

function App() {
  return (
      <div className = "layout">
        <Router>
          <Switch>
            <Route exact path = "/" element = {<Home/>}/>
            <Route path = "/restaurants" element = {<Restaurants/>} />
            <Route path = "/restaurants/white-coffee-spot" element = {<WhiteCoffeeSpot/>}/>
            <Route path = "/restaurants/shawarma-el-khebbez" element = {<ShawarmaElKhebbez/>}/>
            <Route path = "/restaurants/le-bleu" element = {<LeBleu/>}/>
            <Route path = "/restaurants/five-am" element = {<FiveAM/>}/>
            <Route path = "/restaurants/le-train-bleu" element = {<LTB/>}/>
            <Route path = "/about" element = {<About/>}/>
            <Route path = "/catering" element = {<Catering/>}/>
            <Route path = "/contact" element = {<Contact/>}/>
          </Switch>
        </Router>
      </div>
  );
}

export default App;
