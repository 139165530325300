import styled from "styled-components";
import {HeaderFive, AboutFive, ConceptFive, FoodFive, GalleryFive, ContactFive} from "../components/fiveAMRes";

function FiveAM({}) {
    
    
    return (
        <div>
            <HeaderFive/>
                <section id = "category_1">
                    <AboutFive/>
                </section>
                <section id = "category_2">
                    <ConceptFive/>
                </section>
                <section id = "category_3">
                    <FoodFive/>
                </section>
                <section id = "category_4">
                    <GalleryFive/>
                </section>
                <section id = "category_4">
                    <ContactFive/>
                </section>
        
        </div>
    )
}

export default FiveAM;