import { MainContainerStyled, SectionHeadingStyled, ParagraphStyled } from "../RestaurantsCommonComps/AboutStyled";

function AboutSek({}) {
    return (
        <>
            <MainContainerStyled>
                <SectionHeadingStyled>
                    About
                </SectionHeadingStyled>
                <ParagraphStyled>
                    <p>
                        In Style, Shawarma El khebbez re-creates the street food / shawarma experience, using
                        premium ingredients and freshly baked bread
                    </p>
                </ParagraphStyled>
            </MainContainerStyled>
        </>
    )
}

export default AboutSek;