import {useState, useEffect} from "react";
import { MainContainerStyled, ImageContainerStyled, ImageContainerMobileStyled, TextContainerStyled, PageHeadingStyled, ParagraphStyled, ButtonContainerStyled } from "../RestaurantsCommonComps/FoodStyled";


function FoodSek({}) {

    const [isDesktop, setIsDesktop] = useState(true);

    function updateScreenSize() {
        if(window.innerWidth <= 500) setIsDesktop(false);
        else setIsDesktop(true);
    }

    useEffect(() => {
        window.addEventListener("resize", updateScreenSize);

        return () => {
            window.removeEventListener("resize", updateScreenSize);
        }
    })

    if(isDesktop) {
        return (
            <>
                <MainContainerStyled>
                        <ImageContainerStyled>
                            <img src = "/sek-designs/backgroundImgs/arrm-sek-food-section-1.jpg" alt = "food section background" />
                        </ImageContainerStyled>
                        <TextContainerStyled>
                            <PageHeadingStyled style = {{color: "#00547B", fontWeight: "bold"}}>
                                Food
                            </PageHeadingStyled>
                            <ParagraphStyled style = {{color: "#00547B"}}>
                                <p>
                                    SEK is one of few that provides freshly baked artisan bread and quality arabic meats that are
                                    distinguished in the form of care and feeding habits of the cattle and sheep. It is also the only restaurant
                                    in Qatar to provide vegan and vegetarian shawarma sandwiches. 
                                </p>
                            </ParagraphStyled>
                            <ButtonContainerStyled style = {{backgroundColor: "#E43D30"}}>
                                <a href = "https://lovelysunnyday.com/restaurants/menus/shawarma-el-khebbez" target="_blank"> 
                                    <span>view menu</span>
                                </a>
                            </ButtonContainerStyled>
                        </TextContainerStyled>
                    </MainContainerStyled>
            </>
        )
    }

    if(!isDesktop) {
        return (
            <>
                <MainContainerStyled>
                    <ImageContainerMobileStyled>
                        <img src = "/sek-designs/phone_backgroundImgs/arrm-sek-food-phone-1.jpg" />
                    </ImageContainerMobileStyled>
                    <TextContainerStyled>
                        <PageHeadingStyled style = {{color: "#00547B", fontWeight: "bold"}}>
                            Food
                        </PageHeadingStyled>
                        <ParagraphStyled style = {{color: "#00547B"}}>
                            <p>
                                SEK is one of few that provides freshly baked artisan bread and quality arabic meats that are
                                distinguished in the form of care and feeding habits of the cattle and sheep. It is also the only restaurant
                                in Qatar to provide vegan and vegetarian shawarma sandwiches.
                            </p>
                        </ParagraphStyled>
                        <ButtonContainerStyled style = {{backgroundColor: "#E43D30"}}>
                            <a href = "https://lovelysunnyday.com/restaurants/menus/white-coffee-spot" target="_blank"> 
                                <span>view menu</span>
                            </a>
                        </ButtonContainerStyled>
                    </TextContainerStyled>
                </MainContainerStyled>
            </>
        )
    }
}

export default FoodSek;

