import {useState, useEffect} from "react";
import { MainContainerStyled, ImageContainerStyled, ImageContainerMobileStyled, TextContainerStyled, PageHeadingStyled, ParagraphStyled, ButtonContainerStyled } from "../RestaurantsCommonComps/FoodStyled";


function FoodLB({}) {

    const [isDesktop, setIsDesktop] = useState(true);

    function updateScreenSize() {
        if(window.innerWidth <= 500) setIsDesktop(false);
        else setIsDesktop(true);
    }

    useEffect(() => {
        window.addEventListener("resize", updateScreenSize);

        return () => {
            window.removeEventListener("resize", updateScreenSize);
        }
    })

    if(isDesktop) {
        return (
            <>
                <MainContainerStyled>
                        <ImageContainerStyled>
                            <img src = "/lb-designs/backgroundImgs/ARRM-LBPage-Images-03.jpg" alt = "food section background" />
                        </ImageContainerStyled>
                        <TextContainerStyled>
                            <PageHeadingStyled style = {{color: "#AA182C", fontWeight: "bold"}}>
                                Food
                            </PageHeadingStyled>
                            <ParagraphStyled style = {{color: "#AA182C"}}>
                                <p>
                                With Multinational cuisine,
                                Le Bleu offers constantly renovated menu with cooking using a variety of traditional modern cooking techniques to bring out the best flavors and culinary experience.
                                </p>
                            </ParagraphStyled>
                            <ButtonContainerStyled style = {{backgroundColor: "#006580"}}>
                                <a href = "https://lovelysunnyday.com/restaurants/menus/le-bleu" target="_blank"> 
                                    <span style = {{color: "#F5A800"}}>view menu</span>
                                </a>
                            </ButtonContainerStyled>
                        </TextContainerStyled>
                    </MainContainerStyled>
            </>
        )
    }

    if(!isDesktop) {
        return (
            <>
                <MainContainerStyled>
                    <ImageContainerMobileStyled>
                        <img src = "/lb-designs/phone_backgroundImgs/ARRM-LBPage-PhoneImages-03.jpg" />
                    </ImageContainerMobileStyled>
                    <TextContainerStyled>
                        <PageHeadingStyled style = {{color: "#AA182C", fontWeight: "bold"}}>
                            Food
                        </PageHeadingStyled>
                        <ParagraphStyled style = {{color: "#AA182C"}}>
                            <p>
                                With Multinational cuisine, Le Bleu offers constantly renovated menu with cooking
                                using a variety of traditional modern techniques to bring out the best flavors and
                                culinary expereince.
                            </p>
                        </ParagraphStyled>
                        <ButtonContainerStyled style = {{backgroundColor: "#006580"}}>
                            <a href = "https://lovelysunnyday.com/restaurants/menus/le-bleu" target="_blank"> 
                                <span style = {{color: "#F5A800"}}>view menu</span>
                            </a>
                        </ButtonContainerStyled>
                    </TextContainerStyled>
                </MainContainerStyled>
            </>
        )
    }
}

export default FoodLB;