import {useEffect, useState} from "react";
import styled from "styled-components";
import {styled as muiStyled} from "@mui/material/styles"
import {Grid, Paper, Box, ImageList, ImageListItem} from "@mui/material";
import {Masonry} from "@mui/lab";


function GalleryLB() {

    const [isDesktop, setIsDesktop] = useState(true);
    

    //triggers phone size
    function updateScreenSize() {
        if(window.innerWidth <= 1110) setIsDesktop(false);
        else setIsDesktop(true);
        console.log(isDesktop);
    }
    
    useEffect(() => {
        window.addEventListener("resize", updateScreenSize);
    
        return () => {
            window.removeEventListener("resize", updateScreenSize);
        }
    })


    if(isDesktop) {
        return (
            <>
                <SectionHeadingStyled>
                    <span>Gallery</span>
                </SectionHeadingStyled>
                <GridContainerStyled>
                  <div>
                    <Grid container spacing = {2} style = {{padding: "150px", paddingTop: "0px"}}>
                        <Grid item xs = {3}>
                            <FlexBoxStyled>
                                <ImageContainerStyled style = {{marginBottom: "20px"}}>
                                    <img src = "/lb-designs/grid_imgs/desktop-grid-image-1.jpg"/>
                                </ImageContainerStyled>
                                <ImageContainerStyled>
                                    <img src = "/lb-designs/grid_imgs/desktop-grid-image-2.jpg"/>
                                </ImageContainerStyled>
                            </FlexBoxStyled>
                        </Grid>
                        <Grid item xs = {3}>
                            <ImageContainerStyled>
                                <img src = "/lb-designs/grid_imgs/desktop-grid-image-3.jpg"/>
                            </ImageContainerStyled>
                        </Grid>
                        <Grid item xs = {6}>
                            <ImageContainerStyled>
                                <img src = "/lb-designs/grid_imgs/desktop-grid-image-4.jpg"/>
                            </ImageContainerStyled>
                            <FlexBoxRowStyled style = {{marginTop: "22px", gap: "20px"}}>
                                <ImageContainerStyled>
                                    <img src = "/lb-designs/grid_imgs/desktop-grid-image-5.jpg"/>
                                </ImageContainerStyled>
                                <ImageContainerStyled>
                                    <img src = "/lb-designs/grid_imgs/desktop-grid-image-6.jpg"/>
                                </ImageContainerStyled>
                            </FlexBoxRowStyled>
                        </Grid>
                        <Grid item xs = {3}>
                            <ImageContainerStyled>
                                <img src = "/lb-designs/grid_imgs/desktop-grid-image-7.jpg"/>
                            </ImageContainerStyled>
                        </Grid>
                        <Grid item xs = {3}>
                            <ImageContainerStyled>
                                <img src = "/lb-designs/grid_imgs/desktop-grid-image-8.jpg"/>
                            </ImageContainerStyled>
                        </Grid>
                        <Grid item xs = {6}>
                            <ImageContainerStyled>
                                <img  style = {{height: "100%"}} src = "/lb-designs/grid_imgs/desktop-grid-image-9.jpg"/>
                            </ImageContainerStyled>
                        </Grid>
                    </Grid>
                  </div>
                </GridContainerStyled>
            </>
        )
    }

    if(!isDesktop) {
        return (
            <>
                <SectionHeadingStyled>
                    <span>Gallery</span>
                </SectionHeadingStyled>
                <Grid container spacing = {2} style = {{padding: "20px"}}>
                    <Grid item xs = {12}>
                        <ImageContainerStyled>
                                <img  style = {{height: "100%"}} src = "/lb-designs/grid_imgs/desktop-grid-image-4.jpg"/>
                        </ImageContainerStyled>
                    </Grid>
                    <Grid item xs = {12}>
                        <FlexBoxRowStyled >
                            <ImageContainerStyled>
                                <img  style = {{height: "100%"}} src = "/lb-designs/grid_imgs/desktop-grid-image-5.jpg"/>
                            </ImageContainerStyled>
                            <ImageContainerStyled>
                                <img  style = {{height: "100%"}} src = "/lb-designs/grid_imgs/desktop-grid-image-6.jpg"/>
                            </ImageContainerStyled>
                        </FlexBoxRowStyled>
                    </Grid>
                    <Grid item xs = {12}>
                        <ImageContainerStyled>
                            <img  style = {{height: "100%"}} src = "/lb-designs/grid_imgs/desktop-grid-image-9.jpg"/>
                        </ImageContainerStyled>
                    </Grid>
                    <Grid item xs = {12}>
                        <FlexBoxRowStyled>
                            <ImageContainerStyled>
                                <img  style = {{height: "100%"}} src = "/lb-designs/grid_imgs/desktop-grid-image-7.jpg"/>
                            </ImageContainerStyled>
                            <ImageContainerStyled>
                                <img  style = {{height: "100%"}} src = "/lb-designs/grid_imgs/desktop-grid-image-8.jpg"/>
                            </ImageContainerStyled>
                        </FlexBoxRowStyled>
                    </Grid>
                    <Grid item xs = {6}>
                        <FlexBoxStyled>
                            <ImageContainerStyled>
                                <img  style = {{height: "100%"}} src = "/lb-designs/grid_imgs/desktop-grid-image-1.jpg"/>
                            </ImageContainerStyled>
                            <ImageContainerStyled>
                                <img  style = {{height: "100%"}} src = "/lb-designs/grid_imgs/desktop-grid-image-2.jpg"/>
                            </ImageContainerStyled>
                        </FlexBoxStyled>
                    </Grid>
                    <Grid item xs = {6}>
                        <ImageContainerStyled>
                        <img  style = {{height: "100%"}} src = "/lb-designs/grid_imgs/desktop-grid-image-3.jpg"/>
                        </ImageContainerStyled>
                    </Grid>
                </Grid>
            </>
        )
    }
}

const SectionHeadingStyled = styled.div`
    position: relative;
    width: 100%;
    padding-left: 150px;
`
const GridContainerStyled = styled.div`
   position: relative;
   display: flex;
   justify-content: center;
   padding: 20px 0px;
`

const FlexBoxStyled = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;

`

const FlexBoxRowStyled = styled.div`
    position: relative;
    display: flex;
    gap: 20px;

 
`

const ImageContainerStyled = styled.div`
    position: relative;
 
    
    img {
        width: 100%;

    }
`

export default GalleryLB;