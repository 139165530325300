import styled from "styled-components";
import {useState, useEffect} from "react";


function ConceptLB({}) {

    const [isDesktop, setIsDesktop] = useState(true);

    function updateScreenSize() {
        if(window.innerWidth <= 500) setIsDesktop(false);
        else setIsDesktop(true);
        console.log(isDesktop);
    }

    useEffect(() => {
        window.addEventListener("resize", updateScreenSize);

        return () => {
            window.removeEventListener("resize", updateScreenSize);
        }
    })

    if(isDesktop) {
        return (
            <>
                <MainContainerStyled>
                    <ImageContainerStyled>
                        <img src = "/lb-designs/backgroundImgs/arrm-lb-cover-2.jpg"/>
                    </ImageContainerStyled>
                    <TextContainerStyled>
                        <PageHeadingStyled>
                            Concept
                        </PageHeadingStyled>
                        <ParagraphStyled>
                            <p>
                                Located in Doha Festival City - Luxury Area and by the VIP entrace, Le Bleu sends a
                                welcoming message to culinary enthusiasts to enjoy a swift and refined form of dining.
                            </p>
                        </ParagraphStyled>
                    </TextContainerStyled>
                </MainContainerStyled>
            </>
        )
    }

    if(!isDesktop) {
        return (
            <>
                <MainContainerStyled>
                    <ImageContainerMobileStyled>
                        <img src = "/lb-designs/phone_backgroundImgs/arrm-lb-cover-concept.jpg"/>
                    </ImageContainerMobileStyled>
                    <TextContainerStyled>
                        <PageHeadingStyled>
                            Concept
                        </PageHeadingStyled>
                        <ParagraphStyled>
                            <p>
                            Located in Doha Festival City - Luxury Area and by the VIP entrance,
                            Le Bleu sends a welcoming message to culinary enthusiasts to enjoy a swift and refined form of dining with richer experience by the terrace or a more cozy and personal touch inside the restaurant.
                            </p>
                        </ParagraphStyled>
                    </TextContainerStyled>
                </MainContainerStyled>
            </>
        )
    }
}

const MainContainerStyled = styled.div`
    position: relative;
    width: 100%;
    aspect-ratio: 16/9;
`

const ImageContainerStyled = styled.div`
    position: absolute;
    inset: 0;
    width: 100%;

    img {
        position: absolute; 
        width: 100%;
        aspect-ratio: 16/9;
    }
`

const ImageContainerMobileStyled = styled.div`
position: relative;
width: 100%;

img {
   
    width: 100%;
}
`

const TextContainerStyled = styled.div`
    position: absolute;
    inset:0;
    width: 25%;
    color: #F1EFD9;
    padding-left: 150px;
    margin-top: 50px;
    @media(max-width: 800px) {
        padding-left: 80px;
        width: 80%;
    }

    @media(max-width: 500px) {
        display: block;
        padding: 20px 30px;
        width: 68%;
    }

`

const PageHeadingStyled = styled.div`
     position: relative;
    font-weight: bold;
    font-size: 20px;


    @media (max-width: 600px) {  
    }
`

const ParagraphStyled = styled.div`
    position: relative;
    font-size: 18px;

    @media (max-width: 600px) {
    }
`

export default ConceptLB;
