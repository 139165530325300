import {navLinks} from "../data/navLinks";
import { NavBarContainerStyled, NavLinkStyled } from "../styles";
import {useRef} from "react";
import {HashLink as Link} from "react-router-hash-link";

function NavBarContainer({handleMobileBurger}) {
    const navLinksArray = useRef([]);

    function handleFunction(index ) {
        console.log(navLinksArray.current);
        navLinksArray.current.map((category, i) => {
            if(index == i) {
                navLinksArray.current[index].style.color = "#D49D7C";
                if(window.innerWidth <= 420) handleMobileBurger(false);
            } else {
                navLinksArray.current[i].style.color = "white";
            }
        })
    }
        return (
            <NavBarContainerStyled>
                {
                    navLinks.map(({name, section_id}, index) => {
                        return (
                            <NavLinkStyled>
                                <Link to = {section_id}
                                      style = {{color: "white", fontFamily: "CenturyGothic"}}
                                      onClick = {()=> handleFunction(index)}>
                                    <span ref = {el => navLinksArray.current[index] = el}>{name}</span>
                                </Link>
                            </NavLinkStyled>
                            )
                    })
                }
            </NavBarContainerStyled>
        )
}

export default NavBarContainer;