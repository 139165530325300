import { MainContainerStyled, SectionHeadingStyled, ParagraphStyled } from "../RestaurantsCommonComps/AboutStyled";


function AboutLTB({}) {
    return (
        <>
            <MainContainerStyled>
                <SectionHeadingStyled>
                    About
                </SectionHeadingStyled>
                <ParagraphStyled>
                    A European architectural masterpiece on the land of Qatar, is Le Train Bleu, one of the most unique and luxurious French restaurants.
                </ParagraphStyled>
            </MainContainerStyled>
        </>
    )
}

export default AboutLTB;