import styled from "styled-components";
import {useState, useEffect} from "react";
import {FlexBoxStyled, FlexBoxContainerStyled, TextBoxWrapperStyled, TextBoxStyled, GoogleMapStyled, ButtonContainerStyled } from "../RestaurantsCommonComps/ContactStyled";
import { MainContainerStyled, SectionHeadingStyled, ParagraphStyled } from "../RestaurantsCommonComps/AboutStyled";


function ContactLB({}) {
    const [isDesktop, setIsDesktop] = useState(true);

    function updateScreenSize() {
        if(window.innerWidth <= 500) setIsDesktop(false);
        else setIsDesktop(true);
        console.log(isDesktop);
    }

    useEffect(() => {
        window.addEventListener("resize", updateScreenSize);

        return () => {
            window.removeEventListener("resize", updateScreenSize);
        }
    })

    if(isDesktop) {
        return (
            <>
                <MainContainerStyled>
                        <SectionHeadingStyled>Contact</SectionHeadingStyled>
                        <FlexBoxStyled>
                            <FlexBoxContainerStyled>
                                <TextBoxWrapperStyled>
                                    <TextBoxStyled>
                                        <span>Le Bleu</span>
                                        <br/>
                                        <span>P.O. Box</span>
                                        <br/>
                                        <span>Phone</span>
                                        <br/>
                                        <span>Email</span>
                                        <br/>
                                        <span>Address</span>
                                    </TextBoxStyled>
                                    <TextBoxStyled>
                                        <span>Working Hours</span>
                                        <br/>
                                        <span>Sunday - Wednesday</span>
                                    </TextBoxStyled>
                                    <TextBoxStyled>
                                        <span>Follow Us</span>
                                        <br/>
                                        <a href = "https://linktr.ee/lepetittrainbleu" alt = "linktree of shawarma el khebbez" target = "_blank" style = {{color: "#84DADE"}}>
                                                https://linktr.ee/lepetittrainbleu
                                        </a>
                                    </TextBoxStyled>
                                </TextBoxWrapperStyled>
                                <GoogleMapStyled>
                                <iframe 
                                    className = "map_style"
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3604.5971305784624!2d51.4407577!3d25.3848152!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e45ddfb60fab04b%3A0x7fc458fcae32a8f9!2sLe%20Petit%20Train%20Bleu!5e0!3m2!1sen!2sqa!4v1712138978205!5m2!1sen!2sqa">
                                </iframe>
                                </GoogleMapStyled>
                            </FlexBoxContainerStyled>
                        </FlexBoxStyled>
                    </MainContainerStyled>
            </>
        )
    }

    if(!isDesktop) {
        return (
            <>
                <MainContainerStyled>
                    <SectionHeadingStyled>Contact</SectionHeadingStyled>
                        <TextBoxWrapperStyled>
                            <TextBoxStyled>
                                <span>Le Bleu</span>
                                <br/>
                                <span>P.O. Box</span>
                                <br/>
                                <span>Phone</span>
                                <br/>
                                <span>Email</span>
                                <br/>
                                <span>Address:</span>
                            </TextBoxStyled>
                            <TextBoxStyled>
                                <span>Working Hours</span>
                                <br/>
                                <span>Sunday - Wednesday 7 AM - 7 PM</span>
                                <br/>
                                <span>Friday - Closed</span>
                            </TextBoxStyled>
                            <TextBoxStyled>
                                <span>Follow Us</span>
                                <br/>
                                <a href = "https://linktr.ee/lepetittrainbleu" alt = "linktree of shawarma el khebbez" target = "_blank" style = {{color: "#84DADE"}}>
                                        https://linktr.ee/lepetittrainbleu
                                </a>
                            </TextBoxStyled>
                            <TextBoxStyled>
                                <ButtonContainerStyled>
                                <a href = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3604.5971305784624!2d51.4407577!3d25.3848152!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e45ddfb60fab04b%3A0x7fc458fcae32a8f9!2sLe%20Petit%20Train%20Bleu!5e0!3m2!1sen!2sqa!4v1712138978205!5m2!1sen!2sqa" target = "_blank"> 
                                    <span>Our Location</span>
                                </a>
                                </ButtonContainerStyled>
                            </TextBoxStyled>
                        </TextBoxWrapperStyled>
                        
                </MainContainerStyled>
            </>
        )
    }
}

export default ContactLB;