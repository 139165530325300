import { NavBarStyled } from "../styles";
import LogoContainer from "./LogoContainer";
import NavBarContainer from "./NavBarContainer";
import styled from "styled-components";

function NavBar({}) {
    return (
        <NavBarWrapperStyled>
            <NavBarStyled>
                <LogoContainer/>
                <NavBarContainer/>
            </NavBarStyled>
        </NavBarWrapperStyled>
    )
}

const NavBarWrapperStyled = styled.div`
    position: fixed;
    background-color: #152c3d;
    display:flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0px;
    height: 125px;
    z-index: 1;
`


export default NavBar;