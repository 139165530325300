import styled from "styled-components";

const MobileMainContainerStyled = styled.div`
    position: fixed;
    inset:0;
    display: flex;
    height: 10px;
    justify-content: space-between;
    padding: 20px 20px;
    z-index: 1;
    background-color: white
 
`
const HomePageStyled = styled.div`
    position: relative;
    top: 125px;

    @media (max-width: 420px) {
        top: 0px;
    }
`



const MobileHomePageImageContainer = styled.div`
    position: relative;
    
    img {
        width: 100%;
    }
`
const RestaurantsPageStyled = styled.div`
    position: relative;
    padding-bottom: 50px;
    margin-top: 60px;
  
    @media (max-width: 420px) {
        top: 50px;
    }
`

const ContactPageStyled = styled.div`
    position: relative;
    padding-bottom: 60px;
    display: flex;
    justify-content: center;
`

export {
    HomePageStyled,
    RestaurantsPageStyled,
    MobileHomePageImageContainer,
    MobileMainContainerStyled,
    ContactPageStyled,
}