import styled from "styled-components";
import {Grid} from "@mui/material";
import  {useEffect, useState} from "react"


function GalleryFive({}) {

    const [isDesktop, setIsDesktop] = useState(true);
    

    //triggers phone size
    function updateScreenSize() {
        if(window.innerWidth <= 1110) setIsDesktop(false);
        else setIsDesktop(true);
        console.log(isDesktop);
    }
    
    useEffect(() => {
        window.addEventListener("resize", updateScreenSize);
    
        return () => {
            window.removeEventListener("resize", updateScreenSize);
        }
    })


    if(isDesktop) {
        return (
            <>
                <SectionHeadingStyled>
                    <span>Gallery</span>
                </SectionHeadingStyled>
                <GridContainerStyled>
                  <div>
                    <Grid container spacing = {2} style = {{padding: "150px", paddingTop: "0px"}}>
                        <Grid item xs = {3}>
                            <FlexBoxStyled>
                                <ImageContainerStyled style = {{marginBottom: "20px"}}>
                                    <img src = "/fiveAM-designs/grid_imgs/ARRM-FiveAMPage-Images-01.jpg"/>
                                </ImageContainerStyled>
                                <ImageContainerStyled>
                                    <img src = "/fiveAM-designs/grid_imgs/ARRM-FiveAMPage-Images-02.jpg"/>
                                </ImageContainerStyled>
                            </FlexBoxStyled>
                        </Grid>
                        <Grid item xs = {3}>
                            <ImageContainerStyled>
                                <img src = "/fiveAM-designs/grid_imgs/ARRM-FiveAMPage-Images-03.jpg"/>
                            </ImageContainerStyled>
                        </Grid>
                        <Grid item xs = {6}>
                            <ImageContainerStyled>
                                <img src = "/fiveAM-designs/grid_imgs/ARRM-FIveAMPaGe-Images-04.jpg"/>
                            </ImageContainerStyled>
                            <FlexBoxRowStyled style = {{marginTop: "22px", gap: "20px"}}>
                                <ImageContainerStyled>
                                    <img src = "/fiveAM-designs/grid_imgs/arrm-fiveampage-images-05.jpg"/>
                                </ImageContainerStyled>
                                <ImageContainerStyled>
                                    <img src = "/fiveAM-designs/grid_imgs/arrm-fiveampage-images-06.jpg"/>
                                </ImageContainerStyled>
                            </FlexBoxRowStyled>
                        </Grid>
                    </Grid>
                  </div>
                </GridContainerStyled>
            </>
        )
    }

    if(!isDesktop) {
        return (
            <>
                <SectionHeadingStyled>
                    <span>Gallery</span>
                </SectionHeadingStyled>
                <Grid container spacing = {2} style = {{padding: "20px"}}>
                    <Grid item xs = {6}>
                        <FlexBoxStyled>
                                <ImageContainerStyled style = {{marginBottom: "20px"}}>
                                    <img src = "/fiveAM-designs/grid_imgs/ARRM-FiveAMPage-Images-01.jpg"/>
                                </ImageContainerStyled>
                                <ImageContainerStyled>
                                    <img src = "/fiveAM-designs/grid_imgs/ARRM-FiveAMPage-Images-02.jpg"/>
                                </ImageContainerStyled>
                        </FlexBoxStyled>
                    </Grid>
                    <Grid item xs = {6}>
                        <ImageContainerStyled>
                            <img src = "/fiveAM-designs/grid_imgs/ARRM-FiveAMPage-Images-03.jpg"/>
                        </ImageContainerStyled>
                    </Grid>
                    <Grid item xs = {12}>
                        <ImageContainerStyled>
                            <img src = "/fiveAM-designs/grid_imgs/ARRM-FIveAMPaGe-Images-04.jpg"/>
                        </ImageContainerStyled>
                    </Grid>
                    <Grid item xs = {6}>
                        <ImageContainerStyled>
                            <img src = "/fiveAM-designs/grid_imgs/arrm-fiveampage-images-05.jpg"/>
                        </ImageContainerStyled>
                    </Grid>
                    <Grid item xs = {6}>
                        <ImageContainerStyled>
                                    <img src = "/fiveAM-designs/grid_imgs/arrm-fiveampage-images-06.jpg"/>
                        </ImageContainerStyled>
                    </Grid>
                </Grid>
            </>
        )
    }
}

const SectionHeadingStyled = styled.div`
    position: relative;
    width: 100%;
    padding-left: 150px;
    font-weight: bold;
    font-size: 20px;
    
`
const GridContainerStyled = styled.div`
   position: relative;
   display: flex;
   justify-content: center;
   padding: 20px 0px;
`

const FlexBoxStyled = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;

`

const FlexBoxRowStyled = styled.div`
    position: relative;
    display: flex;

 
`

const ImageContainerStyled = styled.div`
    position: relative;
 
    
    img {
        width: 100%;

    }
`

export default GalleryFive;