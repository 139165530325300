import styled from "styled-components";
import { HomePageStyled, MobileHomePageImageContainer, MobileMainContainerStyled } from "../styles";
import {useEffect, useState} from "react";
import NavBar from '../components/NavBar';
import { LogoContainer, MobileBurgerContainer, MobileNavBarContainer, RestaurantsFlexBox } from "../components";
import {Restaurants, About, Contact} from "/";


function Home({}) {
    let [isDesktop, setIsDesktop] = useState(true);
    let [isMobileNavBar, setIsMobileNavBar] = useState(false);

    //track screen size for making a responsive page on mobile and desktop
    function updateScreenSize() {
        if(window.innerWidth <= 420) setIsDesktop(false);
        else setIsDesktop(true);
    }

    //activate the nav bar on a mobile screen
    function handleMobileBurger(navBarController) {
        console.log("clicking on mobile bar");
        if(navBarController == true) setIsMobileNavBar(true);
        else setIsMobileNavBar(false);
    }

    useEffect(() => {
        window.addEventListener("resize", updateScreenSize);

        return () => {
            window.removeEventListener("resize", updateScreenSize);
        }
    }, [])

    if(window.innerWidth > 420) {
        return (
            <>
                <NavBar/>
                <HomePageStyled>
                
                    <ImageContainerStyled>
                        <img src = "/HomePageCover.jpg" alt = "Al Rayyan Restaurant management home page cover photo"/>
                    </ImageContainerStyled>
            
                    <section id = "1">
                        <Restaurants/>
                    </section>
                    <section id = "2">
                        <About/>
                    </section>
                    <section id = "4">
                        <Contact/>
                    </section>
                </HomePageStyled>
            </>
        )
    }

    if(window.innerWidth <= 420) {
        return (
            <>
            
                <HomePageStyled>
                {isMobileNavBar && <MobileNavBarContainer className = "animate__animated animate__slideInRight" handleMobileBurger = {handleMobileBurger}/>}
                    <MobileHomePageImageContainer>
                        <img src = "/HomePageCoverMobile.jpg" alt = "Al Rayyan Restaurant management home page cover photo"/>
                    </MobileHomePageImageContainer>
                    <LogoContainer/>
                    <MobileMainContainerStyled style ={{background: "transparent"}}>
                        <MobileBurgerContainer handleMobileBurger = {handleMobileBurger}/>
                    </MobileMainContainerStyled>
                    <section id = "1">
                        <Restaurants/>
                    </section>
                    <section id = "2">
                        <About/>
                    </section>
                    <section id = "4">
                        <Contact/>
                    </section>
                </HomePageStyled>
                
            </>
        )
    }
}


const ImageContainerStyled = styled.div`
    position: relative;
    width: 100%;
    height: 16/9;

    img {
        width: 100%;
    }
`

const RestaurantsFlexBoxStyled = styled.div`
    position: relative;
    width: 100%;
`


export default Home;