function ContactPageMapContainer() {
    return (
        <>
            <iframe 
                className="map_style"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d758.3758623753727!2d51.51656854539625!3d25.288614065916835!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e45c52d602f31bd%3A0x4af9a70d6cc31082!2sAriane%20Tower!5e0!3m2!1sen!2sqa!4v1671286958938!5m2!1sen!2sqa">
            </iframe>
        </>
    )
}

export default ContactPageMapContainer;