import styled from "styled-components";

const MainContainerStyled = styled.div`
    position: relative;
    width: 100%;
`

const ImgContainerStyled = styled.div`
    position: relative;
    inset: 0;

    img {
        position: relative;
        inset:0;
        width:100%;
    }
`

const BackLogoContainerStyled = styled.div`
    position: fixed;
    inset:0;
    cursor: pointer;
    z-index:1;
    width: 30px;
    height: 30px;
    top: 50px;
    left: 40px;

    
    img {
        width: 100%;
    }

    @media (max-width: 800px) {
        left: 20px;
    }

    @media (max-width: 500px) {
        width: 15px;
        height: 15px;
        left: 5px;
    }
`

export {
    MainContainerStyled,
    ImgContainerStyled,
    BackLogoContainerStyled,
}