import styled from "styled-components";

const MainContainerStyled = styled.div`
    position: relative;
    width: 100%;

`

const PageHeadingStyled = styled.div`
    position: relative;
    text-align: center;
    margin-bottom: 30px;
    margin-top: 50px;
    font-weight: bold;

    @media(max-width: 500px) {
        text-align: left;
        padding: 0px 30px;
    }
`

const FlexBoxStyled = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    

`

const FlexBoxContainerStyled = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 50%;
    
    
`

const TextBoxWrapperStyled = styled.div`
    position: relative;
`

const TextBoxStyled = styled.div`
    position: relative;
    margin-bottom: 66px;

    @media(max-width: 500px) {
        margin-bottom: 20px;
    }
`

const GoogleMapStyled = styled.div`
   position: relative;
`

const ButtonContainerStyled = styled.div`
    position: relative;
    padding: 0px 10px;
    background-color: #152C3D;
    cursor: pointer;
    width: 92px;
    height: 28px;
    border: 2px;
    border-radius: 30px;
    margin-top: 34px;
    font-size: 12px;
    text-align: center;

    a {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        display: flex;
    }

    span {
        color: white;
        margin: 0 auto;
        margin-top: auto;
        margin-bottom: auto;
    }
`

export {
    MainContainerStyled,
    PageHeadingStyled,
    FlexBoxStyled,
    FlexBoxContainerStyled,
    TextBoxWrapperStyled,
    TextBoxStyled,
    GoogleMapStyled,
    ButtonContainerStyled,
}