import { MainContainerStyled, SectionHeadingStyled, ParagraphStyled } from "../RestaurantsCommonComps/AboutStyled";

function AboutLB({}) {
    return (
        <>
            <MainContainerStyled>
                <SectionHeadingStyled>
                    About
                </SectionHeadingStyled>
                <ParagraphStyled>
                    <p>
                        A subsidiary of Le Train Bleu Qatar, operates to elevate the casual dining experience serving a selection of gourmet food. Delivering refined preparations and presentations of aesthetically balanced meals of several contrasts filled with often quite rich courses. With goal to the iconic casual dining destination.
                    </p>
                </ParagraphStyled>
            </MainContainerStyled>
        </>
    )
}

export default AboutLB;