import styled from "styled-components";
import {useState, useEffect} from "react";
import { MainContainerStyled, PageHeadingStyled, FlexBoxStyled, FlexBoxContainerStyled, TextBoxWrapperStyled, TextBoxStyled, GoogleMapStyled, ButtonContainerStyled } from "../RestaurantsCommonComps/ContactStyled";


function ContactSek({}) {
    const [isDesktop, setIsDesktop] = useState(true);

    function updateScreenSize() {
        if(window.innerWidth <= 500) setIsDesktop(false);
        else setIsDesktop(true);
        console.log(isDesktop);
    }

    useEffect(() => {
        window.addEventListener("resize", updateScreenSize);

        return () => {
            window.removeEventListener("resize", updateScreenSize);
        }
    })

    if(isDesktop) {
        return (
            <>
                <MainContainerStyled>
                        <PageHeadingStyled>Contact</PageHeadingStyled>
                        <FlexBoxStyled>
                            <FlexBoxContainerStyled>
                                <TextBoxWrapperStyled>
                                    <TextBoxStyled>
                                        <span>Shawarma El Khebbez</span>
                                        <br/>
                                        <span>P.O. Box</span>
                                        <br/>
                                        <span>Phone</span>
                                        <br/>
                                        <span>Email</span>
                                        <br/>
                                        <span>Address</span>
                                    </TextBoxStyled>
                                    <TextBoxStyled>
                                        <span>Working Hours</span>
                                        <br/>
                                        <span>Sunday - Wednesday</span>
                                    </TextBoxStyled>
                                    <TextBoxStyled>
                                        <span>Follow Us</span>
                                        <br/>
                                        <a href = "https://linktr.ee/shawarmaelkhebbez" alt = "linktree of shawarma el khebbez" target = "_blank" style = {{color: "#84DADE"}}>
                                            https://linktr.ee/shawarmaelkhebbez
                                        </a>
                                    </TextBoxStyled>
                                </TextBoxWrapperStyled>
                                {/* <GoogleMapStyled>
                                <iframe 
                                    className = "map_style"
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3606.583121821928!2d51.524936711470616!3d25.318206626694494!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e45c5d4f841d6fb%3A0xb6b14af1d1bf02fb!2sWhite%20Coffee%20Spot!5e0!3m2!1sen!2sqa!4v1687157402233!5m2!1sen!2sqa">
                                </iframe>
                                </GoogleMapStyled> */}
                            </FlexBoxContainerStyled>
                        </FlexBoxStyled>
                    </MainContainerStyled>
            </>
        )
    }

    if(!isDesktop) {
        return (
            <>
                <MainContainerStyled>
                    <PageHeadingStyled>Contact</PageHeadingStyled>
                        <TextBoxWrapperStyled>
                            <TextBoxStyled>
                                <span>Shawarma El Khebbez</span>
                                <br/>
                                <span>P.O. Box</span>
                                <br/>
                                <span>Phone</span>
                                <br/>
                                <span>Email</span>
                                <br/>
                                <span>Address: Palm Tower, Majilis Al Taawon St.</span>
                            </TextBoxStyled>
                            <TextBoxStyled>
                                <span>Working Hours</span>
                                <br/>
                                <span>Sunday - Wednesday 7 AM - 7 PM</span>
                                <br/>
                                <span>Friday - Closed</span>
                            </TextBoxStyled>
                            <TextBoxStyled>
                                <span>Follow Us</span>
                                <br/>
                                <a href = "https://linktr.ee/shawarmaelkhebbez" alt = "linktree of shawarma el khebbez" target = "_blank" style = {{color: "#84DADE"}}>
                                    https://linktr.ee/shawarmaelkhebbez
                                </a>
                            </TextBoxStyled>
                            <TextBoxStyled>
                                <ButtonContainerStyled>
                                <a href = "https://www.google.com/maps/place/White+Coffee+Spot,+Palm+Tower,+Majlis+Al+Taawon+St,+Doha/@25.318202,51.527517,15z/data=!4m6!3m5!1s0x3e45c5d4f841d6fb:0xb6b14af1d1bf02fb!8m2!3d25.3182018!4d51.527517!16s%2Fg%2F11p4q2kl31?hl=en&gl=QA" target = "_blank"> 
                                    <span>Our Location</span>
                                </a>
                                </ButtonContainerStyled>
                            </TextBoxStyled>
                        </TextBoxWrapperStyled>
                        
                </MainContainerStyled>
            </>
        )
    }
}

export default ContactSek;