import styled from "styled-components";
import {useEffect, useRef, useState} from "react";
import { AboutLTB, ConceptLTB, ContactLTB, FoodLTB, HeaderLTB, GalleryLTB} from "../components/LTBRes";

function LTB({}) {

    const mainContainerRef = useRef(null);
   
    return (
        <>
            <div>
                <HeaderLTB ref = {mainContainerRef}/>
                <section id = "category_1">
                    <AboutLTB/>
                </section>
                <section id = "category_2">
                    <ConceptLTB/>
                </section>
                <section id = "category_3">
                    <FoodLTB/>
                </section>
                <section id = "category_4">
                    <GalleryLTB/>
                </section>
                <section id = "category_5">
                    <ContactLTB/>
                </section>
            </div>
        </>
    )
}

export default LTB;