import { MobileNavBarStyled } from "../styles";
import styled from "styled-components";
import NavBarContainer from "./NavBarContainer";

function MobileNavBarContainer({handleMobileBurger}) {
    return (
        <MobileNavBarStyled>
            <CloseNavBarContainerStyled onClick = {() => handleMobileBurger(false)}>
                <img src = "close bar icon.png"/>
            </CloseNavBarContainerStyled>
            <NavBarContainer handleMobileBurger = {handleMobileBurger}/>
        </MobileNavBarStyled>
    )
}

const CloseNavBarContainerStyled = styled.div`
    position: relative;
    margin-top: 30px;
    margin-bottom: 30px;

    img {
        padding-right: 20px;
        max-width: 8px;
        aspect-ratio: 1/1.8;
        transform: rotate(180deg)
    }
`

export default MobileNavBarContainer;