import {useState, useEffect} from "react";
import {NavBar, MobileNavBarContainer, LogoContainer, MobileBurgerContainer} from "../components"
import { MobileBurgerContainerStyled, MobileMainContainerStyled, } from "../styles";

function Catering({}) {
    let [isDesktop, setIsDesktop] = useState(true);
    let [isMobileNavBar, setIsMobileNavBar] = useState(false);

    //track screen size for making a responsive page on mobile and desktop
    function updateScreenSize() {
        if(window.innerWidth <= 420) setIsDesktop(false);
        else setIsDesktop(true);
    }

    //activate the nav bar on a mobile screen
    function handleMobileBurger(navBarController) {
        if(navBarController == true) setIsMobileNavBar(true);
        else setIsMobileNavBar(false);
    }

    useEffect(() => {
        window.addEventListener("resize", updateScreenSize);

        return () => {
            window.removeEventListener("resize", updateScreenSize);
        }
    }, [])

    if(window.innerWidth > 420) {
        return (
            <>
                <NavBar/>
            </>
        )
    }

    if(window.innerWidth < 420) {
        return (
            <>
               {isMobileNavBar && <MobileNavBarContainer class = "animate__animated animate__slideInRight" handleMobileBurger = {handleMobileBurger}/>}
               <MobileMainContainerStyled>
                    <LogoContainer/>
                    <MobileBurgerContainer handleMobileBurger = {handleMobileBurger}/>
               </MobileMainContainerStyled>
            </>
        )
    }
}

export default Catering;