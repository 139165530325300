import { LogoContainerStyled } from "../styles";
import {Link} from "react-router-dom"

function LogoContainer({}) {
    return (
        <LogoContainerStyled>
            <Link to = "/">
            <img src = "/arrm_logo.png" alt = "al rayyan restaurant management logo"/>
            </Link>
        </LogoContainerStyled>
    )
}

export default LogoContainer;