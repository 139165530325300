import styled from "styled-components";
import {useEffect, useRef, useState} from "react";
import { AboutLB, ConceptLB, ContactLB, FoodLB, HeaderLB, GalleryLB} from "../components/LBRes";

function LeBleu({}) {

    const mainContainerRef = useRef(null);
   
    return (
        <>
            <div>
                <HeaderLB ref = {mainContainerRef}/>
                <section id = "category_1">
                    <AboutLB/>
                </section>
                <section id = "category_2">
                    <ConceptLB/>
                </section>
                <section id = "category_3">
                    <FoodLB/>
                </section>
                <section id = "category_4">
                    <GalleryLB/>
                </section>
                <section id = "category_5">
                    <ContactLB/>
                </section>
            </div>
        </>
    )
}

export default LeBleu;