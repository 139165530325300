import styled from "styled-components";

const MainContainerStyled = styled.div`
    position: relative;
    width: 100%;
    aspect-ratio: 16/9;
    margin-bottom: 50px;
    
`

const ImageContainerStyled = styled.div`
    width: 100%;
    

    img {
        width: 100%;
        aspect-ratio: 16/9; 
    }
 `

 const ImageContainerMobileStyled = styled.div`
    position: relative;
    width: 100%;

    img {
       
        width: 100%;
    }
 `


const TextContainerStyled = styled.div`
    position: absolute;
    inset:0;
    padding-left: 150px;
    padding-right: 150px;
    padding-top: 30px;

    @media(max-width: 800px) {
        padding-left: 80px;
        padding-right: 80px;
    }
    @media(max-width: 500px) {
        padding-left: 30px;
        padding-right: 30px;
    }
`

const PageHeadingStyled = styled.div`
    position: relative;
    font-weight: bold;
    font-size: 20px;

    @media(max-width: 800px) {
    }

    @media(max-width: 900px) {
 
    }

    @media (max-width: 600px) {

    } 
`

const ParagraphStyled = styled.div`
    position: relative;
     width: 50%;
    line-height: 25px;
    font-size: 18px;
    

    @media(max-width: 900px) {
       width: 100%;
    }

    @media (max-width: 500px) {

    }
`

const ButtonContainerStyled = styled.div`
    position: relative;
    background-color: #152C3D;
    cursor: pointer;
    width: 92px;
    height: 28px;
    border: 2px;
    border-radius: 30px;
    margin-top: 34px;
    font-size: 12px;
    text-align: center;

    a {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        display: flex;
    }

    span {
        color: white;
        margin: 0 auto;
        margin-top: auto;
        margin-bottom: auto;
    }
`

export {
    MainContainerStyled,
    ImageContainerStyled,
    ImageContainerMobileStyled,
    TextContainerStyled,
    PageHeadingStyled,
    ParagraphStyled,
    ButtonContainerStyled,
}