import styled from "styled-components";
import {useState, useEffect} from "react";

function ConceptLTB({}) {

    const [isDesktop, setIsDesktop] = useState(true);

    function updateScreenSize() {
        if(window.innerWidth <= 500) setIsDesktop(false);
        else setIsDesktop(true);
        console.log(isDesktop);
    }

    useEffect(() => {
        window.addEventListener("resize", updateScreenSize);

        return () => {
            window.removeEventListener("resize", updateScreenSize);
        }
    })

    if(isDesktop) {
        return (
            <>
                <MainContainerStyled>
                    <ImageContainerStyled>
                        <img src = "/ltb-designs/backgroundImgs/arrm-ltb-cover-2.jpg"/>
                    </ImageContainerStyled>
                    <TextContainerStyled>
                        <PageHeadingStyled>
                            Concept
                        </PageHeadingStyled>
                        <ParagraphStyled>
                            <p>
                            Le Train Bleu represents an amazing memory of one of the important restaurants in the time of the beautiful Parisian era, when traveling by train was considered a kind of luxury, and this is the first time in more than 100 years that Le Train Bleu occupies a place other than Paris.
                            When you enter the restaurant, the interior decoration of the restaurant dazzles you with ancient colors and paintings on the walls and ceilings, reflecting the diversity and richness of the royal heritage of France.
                            </p>
                            <p>
                            It is a place suitable for private parties, as well as for afternoon tea, which is served according to the highest standards of sophistication and luxury. 
                            </p>
                        </ParagraphStyled>
                    </TextContainerStyled>
                </MainContainerStyled>
            </>
        )
    }

    if(!isDesktop) {
        return (
            <>
                <MainContainerStyled>
                    <ImageContainerMobileStyled>
                        <img src = "/ltb-designs/phone_backgroundImgs/arrm-ltb-cover-phone-2.jpg"/>
                    </ImageContainerMobileStyled>
                    <TextContainerStyled>
                        <PageHeadingStyled>
                            Concept
                        </PageHeadingStyled>
                        <ParagraphStyled>
                            <p>
                                Located in Doha Festival City - Luxury Area and by the VIP entrace, Le Bleu sends a
                                welcoming message to culinary enthusiasts to enjoy a swift and refined form of dining.
                            </p>
                        </ParagraphStyled>
                    </TextContainerStyled>
                </MainContainerStyled>
            </>
        )
    }
}

const MainContainerStyled = styled.div`
    position: relative;
    width: 100%;
    aspect-ratio: 16/9;
`

const ImageContainerStyled = styled.div`
    position: absolute;
    inset: 0;
    width: 100%;

    img {
        position: absolute; 
        width: 100%;
        aspect-ratio: 16/9;
    }
`

const ImageContainerMobileStyled = styled.div`
position: relative;
width: 100%;

img {
   
    width: 100%;
}
`

const TextContainerStyled = styled.div`
    position: absolute;
    inset:0;
    width: 25%;
    color: #F1EFD9;
    padding-left: 150px;
    margin-top: 50px;
    @media(max-width: 800px) {
        padding-left: 80px;
        width: 80%;
    }

    @media(max-width: 500px) {
        display: block;
        padding: 20px 30px;
        width: 68%;
    }

`

const PageHeadingStyled = styled.div`
     position: relative;
    font-weight: bold;
    font-size: 20px;


    @media (max-width: 600px) {  
    }
`

const ParagraphStyled = styled.div`
    position: relative;
    font-size: 18px;

    @media (max-width: 600px) {
    }
`

export default ConceptLTB;