import {MobileMainContainerStyled, ContactPageStyled } from "../styles";
import styled from "styled-components";
import { LogoContainer, RestaurantsFlexBox, MobileNavBarContainer, MobileBurgerContainer, ContactPageMainContainer, ContactPageMapContainer } from "../components";
import NavBar from '../components/NavBar';
import {useState, useEffect} from "react";


function Contact({}) {
    let [isDesktop, setIsDesktop] = useState(true);
    let [isMobileNavBar, setIsMobileNavBar] = useState(false);

    //track screen size for making a responsive page on mobile and desktop
    function updateScreenSize() {
        if(window.innerWidth <= 420) setIsDesktop(false);
        else setIsDesktop(true);
    }

    //activate the nav bar on a mobile screen
    function handleMobileBurger(navBarController) {
        if(navBarController == true) setIsMobileNavBar(true);
        else setIsMobileNavBar(false);
    }

    useEffect(() => {
        window.addEventListener("resize", updateScreenSize);

        return () => {
            window.removeEventListener("resize", updateScreenSize);
        }
    }, [])

    if(window.innerWidth > 420) {
        return (
            <>
                <ContactPageStyled>
                    <div>
                    <ContactPageMainContainer/>
                    </div>
                    
                </ContactPageStyled>
            </>
        )
    }

    if(window.innerWidth <= 420) {
        return (
            <>
               <ContactPageStyled>
                    <div>
                        <ContactPageMainContainer/>
                    </div>
                </ContactPageStyled>
            </>
        )
    }
}
 
export default Contact;