import styled from "styled-components";
import {useState, useEffect} from "react";


function Concept({}) {

    const [isDesktop, setIsDesktop] = useState(true);

    function updateScreenSize() {
        if(window.innerWidth <= 500) setIsDesktop(false);
        else setIsDesktop(true);
        console.log(isDesktop);
    }

    useEffect(() => {
        window.addEventListener("resize", updateScreenSize);

        return () => {
            window.removeEventListener("resize", updateScreenSize);
        }
    })

    if(isDesktop) {
        return (
            <>
                <MainContainerStyled>
                    <ImageContainerStyled>
                        <img src = "/wcs-designs/backgroundImgs/arrm-wcs-cover-2.jpg"/>
                    </ImageContainerStyled>
                    <TextContainerStyled>
                        <PageHeadingStyled>
                            Concept
                        </PageHeadingStyled>
                        <ParagraphStyled>
                            <p>
                                BREAK YOU DAILY ROUTINE AND BREATH IN THE VIBRANT
                                AMBIENCE.
                            </p>
                        </ParagraphStyled>
                        <ParagraphStyled>
                            <p>
                                A new speciality coffee experience, combining lifestyle and casual
                                dining. A sophisticated coffee spot, offering a wide variety of coffee
                                roats, desserts and all day sandwiches.
                            </p>
                        </ParagraphStyled>
                        <ParagraphStyled>
                            <p>
                                We envision White Coffee Spot becoming a leading landmark destination amongst
                                speciality coffee shops within the region, in terms of concept, experience, unprecedented menu,
                                and custoemr engagement.
                            </p>
                        </ParagraphStyled>
                    </TextContainerStyled>
                </MainContainerStyled>
            </>
        )
    }

    if(!isDesktop) {
        return (
            <>
                <MainContainerStyled>
                    <ImageContainerMobileStyled>
                        <img src = "/wcs-designs/phone_backgroundsImgs/wcs_concept.jpg"/>
                    </ImageContainerMobileStyled>
                    <TextContainerStyled>
                        <PageHeadingStyled>
                            Concept
                        </PageHeadingStyled>
                        <ParagraphStyled>
                            <p>
                                BREAK YOU DAILY ROUTINE AND BREATH IN THE VIBRANT
                                AMBIENCE.
                            </p>
                        </ParagraphStyled>
                        <ParagraphStyled>
                            <p>
                                A new speciality coffee experience, combining lifestyle and casual
                                dining. A sophisticated coffee spot, offering a wide variety of coffee
                                roats, desserts and all day sandwiches.
                            </p>
                        </ParagraphStyled>
                        <ParagraphStyled>
                            <p>
                                We envision White Coffee Spot becoming a leading landmark destination amongst
                                speciality coffee shops within the region, in terms of concept, experience, unprecedented menu,
                                and custoemr engagement.
                            </p>
                        </ParagraphStyled>
                    </TextContainerStyled>
                </MainContainerStyled>
            </>
        )
    }
}

const MainContainerStyled = styled.div`
    position: relative;
    width: 100%;
    aspect-ratio: 16/9;
`

const ImageContainerStyled = styled.div`
    position: absolute;
    inset: 0;
    width: 100%;

    img {
        position: absolute; 
        width: 100%;
        aspect-ratio: 16/9;
    }
`

const ImageContainerMobileStyled = styled.div`
position: relative;
width: 100%;

img {
   
    width: 100%;
}
`

const TextContainerStyled = styled.div`
    position: absolute;
    inset:0;
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 150px;

    @media(max-width: 800px) {
        padding-left: 80px;
        width: 80%;
    }

    @media(max-width: 500px) {
        display: block;
        padding: 60px 30px;
    }

`

const PageHeadingStyled = styled.div`
     position: relative;
    font-weight: bold;
    font-size: 20px;


    @media (max-width: 600px) {  
    }
`

const ParagraphStyled = styled.div`
    position: relative;
    font-size: 18px;

    @media (max-width: 600px) {
    }
`

export default Concept;
