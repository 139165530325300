import styled from "styled-components";

//All styles related to the navigation bar and top of the page are here

const NavBarStyled = styled.div`
    position: relative;
    display: flex;
    gap: 780px;

    @media (max-width: 1450px) {
        gap: 470px;
    }

    @media (max-width: 1100px) {
        gap: 160px;
    }
`

const NavBarContainerStyled = styled.div`
    position: relative;
    display: flex;
    z-index: 1;
    margin-top: 78px;
    /* padding-right: 150px; */

    gap: 23px;

    @media (max-width: 420px) {
        flex-direction: column;
        padding-left: 20px;
    }
`

const NavLinkStyled = styled.div`
    position: relative;
    display: block;

    span {
        font-size: 14px;
        font-family: "Century Gothic";
    }
`

const LogoContainerStyled = styled.div`
    position: relative;
    width: 124px;
    height:57px;
    margin-top: 33px;
 

    img {
        position: absolute;
        inset: 0;
        width: 100%;
    }

    @media (max-width: 420px) {
        position: absolute;
        inset:0;
        left: 20px;
        top: -8px;
        width: 80px;
    }
`

const MobileBurgerContainerStyled = styled.div`
    position: absolute;
    
    right: 20px;
    width: 30px;
    height: 50px;
    margin-top: 5px;
    

    img {
        position: absolute;
        inset: 0;
        width: 100%;
        aspect-ratio: 1/0.6;
    }
`

const MobileNavBarStyled = styled.div`
    position: fixed;
    right: 0px;
    width: 45%;
    height: 100%;
    background-color: #152c3d;
    z-index:2;
    
`
export {
    NavBarStyled,
    NavBarContainerStyled,
    NavLinkStyled,
    LogoContainerStyled,
    MobileBurgerContainerStyled,
    MobileNavBarStyled,
}