import {useState, useEffect} from "react";
import { MainContainerStyled, ImageContainerStyled, ImageContainerMobileStyled, TextContainerStyled, PageHeadingStyled, ParagraphStyled, ButtonContainerStyled } from "../RestaurantsCommonComps/FoodStyled";

function FoodLTB({}) {

    const [isDesktop, setIsDesktop] = useState(true);

    function updateScreenSize() {
        if(window.innerWidth <= 500) setIsDesktop(false);
        else setIsDesktop(true);
    }

    useEffect(() => {
        window.addEventListener("resize", updateScreenSize);

        return () => {
            window.removeEventListener("resize", updateScreenSize);
        }
    })

    if(isDesktop) {
        return (
            <>
                <MainContainerStyled>
                        <ImageContainerStyled>
                            <img src = "/ltb-designs/backgroundImgs/arrm-ltb-cover-3.jpg" alt = "food section background" />
                        </ImageContainerStyled>
                        <TextContainerStyled>
                            <PageHeadingStyled style = {{color: "#FFFFFF", fontWeight: "bold"}}>
                                Food
                            </PageHeadingStyled>
                            <ParagraphStyled style = {{color: "#FFFFFF"}}>
                                <p>
                                It offers you the opportunity to try a varied menu of delicious French breakfast, lunch and dinner with an Arabic taste based mainly on fresh, healthy products.
                                A variety of choices of meat, poultry and different vegetarian dishes are available, so when you visit Le Train Bleu, be sure to eat French onion soup with meat broth and melted Emmental cheese, and do not forget the cream caramel and French toast
                                </p>
                            </ParagraphStyled>
                            <ButtonContainerStyled style = {{backgroundColor: "#E1C16F"}}>
                                <a href = "https://lovelysunnyday.com/restaurants/menus/le-train-bleu" target="_blank"> 
                                    <span style = {{color: "#011E41"}}>view menu</span>
                                </a>
                            </ButtonContainerStyled>
                        </TextContainerStyled>
                    </MainContainerStyled>
            </>
        )
    }

    if(!isDesktop) {
        return (
            <>
                <MainContainerStyled>
                    <ImageContainerMobileStyled>
                        <img src = "/ltb-designs/phone_backgroundImgs/arrm-ltb-cover-phone-3.jpg" />
                    </ImageContainerMobileStyled>
                    <TextContainerStyled>
                        <PageHeadingStyled style = {{color: "#FFFFFF", fontWeight: "bold"}}>
                            Food
                        </PageHeadingStyled>
                        <ParagraphStyled style = {{color: "#FFFFFF"}}>
                            <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor 
                            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud 
                            exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
                            Duis aute irure dolor in reprehenderit in voluptate velit esse cillum 
                            dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non 
                            proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </p>
                        </ParagraphStyled>
                        <ButtonContainerStyled style = {{backgroundColor: "#E1C16F"}}>
                            <a href = "https://lovelysunnyday.com/restaurants/menus/le-train-bleu" target="_blank"> 
                                <span style = {{color: "#011E41"}}>view menu</span>
                            </a>
                        </ButtonContainerStyled>
                    </TextContainerStyled>
                </MainContainerStyled>
            </>
        )
    }

    return (
        <>
        
        </>
    )
}

export default FoodLTB;