import {useState, useEffect} from "react";
import { MainContainerStyled, ImageContainerStyled, ImageContainerMobileStyled, TextContainerStyled, PageHeadingStyled, ParagraphStyled, ButtonContainerStyled } from "../RestaurantsCommonComps/FoodStyled";

function FoodFive({}) {

    const [isDesktop, setIsDesktop] = useState(true);

    function updateScreenSize() {
        if(window.innerWidth <= 500) setIsDesktop(false);
        else setIsDesktop(true);
    }

    useEffect(() => {
        window.addEventListener("resize", updateScreenSize);

        return () => {
            window.removeEventListener("resize", updateScreenSize);
        }
    })

    if(isDesktop) {
        return (
            <>
                <MainContainerStyled>
                        <ImageContainerStyled>
                            <img src = "/fiveAM-designs/backgroundImgs/arrm-five-cover-3.jpg" alt = "food section background" />
                        </ImageContainerStyled>
                        <TextContainerStyled>
                            <PageHeadingStyled style = {{color: "#FFFFFF", fontWeight: "bold"}}>
                                Food
                            </PageHeadingStyled>
                            <ParagraphStyled style = {{color: "#FFFFFF"}}>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor 
                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud 
                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
                                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum 
                                    dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non 
                                    proident, sunt in culpa qui officia deserunt mollit anim id est laborum
                                </p>
                            </ParagraphStyled>
                            <ButtonContainerStyled style = {{backgroundColor: "#637B30"}}>
                                <a href = "https://drive.google.com/file/d/1p3SK6MWjd5iIrijMfog9I-L7NeQtvIIs/view" target="_blank"> 
                                    <span style = {{color: "#E6DED3"}}>view menu</span>
                                </a>
                            </ButtonContainerStyled>
                        </TextContainerStyled>
                    </MainContainerStyled>
            </>
        )
    }

    if(!isDesktop) {
        return (
            <>
                <MainContainerStyled>
                    <ImageContainerMobileStyled>
                        <img src = "/fiveAM-designs/phone_backgroundImgs/arrm-five-cover-phone-3.jpg" />
                    </ImageContainerMobileStyled>
                    <TextContainerStyled>
                        <PageHeadingStyled style = {{color: "#FFFFFF", fontWeight: "bold"}}>
                            Food
                        </PageHeadingStyled>
                        <ParagraphStyled style = {{color: "#FFFFFF"}}>
                            <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor 
                            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud 
                            exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
                            Duis aute irure dolor in reprehenderit in voluptate velit esse cillum 
                            dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non 
                            proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </p>
                        </ParagraphStyled>
                        <ButtonContainerStyled style = {{backgroundColor: "#637B30"}}>
                            <a href = "https://drive.google.com/file/d/1p3SK6MWjd5iIrijMfog9I-L7NeQtvIIs/view" target="_blank"> 
                                <span style = {{color: "#E6DED3"}}>view menu</span>
                            </a>
                        </ButtonContainerStyled>
                    </TextContainerStyled>
                </MainContainerStyled>
            </>
        )
    }

    return (
        <>
        
        </>
    )
}

export default FoodFive;