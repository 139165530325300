import styled from "styled-components";
import ContactPageMapContainer from "./ContactPageMapContainer";

function ContactPageMainContainer() {
    return (
        <>
            <div style = {{marginBottom: "30px", fontSize: "40px", fontWeight: "bolder", textAlign: "center", marginTop: "120px"}}>
                <span>
                    Make a Connection
                </span>
            </div>
            <ContactPageDetailsFlexBoxStyled>
                <ContactPageDetailsFlexOneStyled style = {{textAlign: "center", padding: "0px 10px"}}>
                <div style = {{marginBottom: "20px"}}>
                    <span>Al Rayyan Restaurant Management</span>
                </div>
                <div>
                    <span>P.O. Box 1966</span>
                </div>
                <div>
                    <span>Phone: <a href = "tel:+97444154252">+974 4415 4252</a></span>
                </div>
                <div>
                    <span>Email: <a href = "mailto:hr@alrayyanqa.com">hr@alrayyanqa.com</a></span>
         
                </div>
                <div style = {{marginBottom: "20px"}}>
                    <span>Address Msheireb, Zone 13</span>
                    <br/>
                    <span>St. 980, Building 8</span>
                </div>
              
                    <div>
                        <span>Working Hours</span>
                    </div>
                    <div>
                        <span>Sunday-Wednesday 8:00 AM - 5:00 PM</span>
                    </div>
                    <div>
                        <span>Thursday 8:00 AM - 2:00 PM</span>
                    </div>
              
                </ContactPageDetailsFlexOneStyled>
                <ContactPageDetailsFlexOneStyled>
                    <ContactPageMapContainer/>
                </ContactPageDetailsFlexOneStyled>
            </ContactPageDetailsFlexBoxStyled>
        </>
    )
}

const PageHeadingStyled = styled.div`
    position: relative;
     span {
        
     }
`

const ContactPageDetailsFlexBoxStyled = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    gap: 127px;
    margin-bottom: 28px;

    @media(max-width: 420px) {
        flex-direction: column;
        align-items: center;
    }

    a {
        color: rgb(212, 157, 124);
    }
`

const ContactPageDetailsFlexOneStyled = styled.div`
    position: relative; 

    span {
        font-size: 18px;
    }
`
export default ContactPageMainContainer;