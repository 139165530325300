import {useState, useEffect} from "react";
import { MainContainerStyled, ImgContainerStyled, BackLogoContainerStyled } from "../RestaurantsCommonComps/HeaderStyled";

function HeaderFive({}) {

    const [isDesktop, setIsDesktop] = useState(true);

    function updateScreenSize() {
        if(window.innerWidth <= 500) setIsDesktop(false);
        else setIsDesktop(true);
    }

    useEffect(() => {
        window.addEventListener("resize", updateScreenSize);

        return () => {
            window.removeEventListener("resize", updateScreenSize);
        }
    },[])

    if(isDesktop) {
        return (
            <>
                <MainContainerStyled>
                    <ImgContainerStyled>
                        <img src = "/fiveAM-designs/backgroundImgs/arrm-five-cover-1.jpg" alt = "Five AM restaurant cover photo"/>
                    </ImgContainerStyled>
                    <BackLogoContainerStyled>
                        <a href = "/restaurants">
                            <img src = "/arrm-back-icon-blue.png" alt = "al rayyan restaurant management go back icon"/>
                        </a>
                    </BackLogoContainerStyled>
                </MainContainerStyled>
            </>
        )
    }

    if(!isDesktop) {
        return (
            <>
                <MainContainerStyled>
                    <ImgContainerStyled>
                        <img src = "/fiveAM-designs/phone_backgroundImgs/arrm-five-cover-phone-1.jpg" alt = "Five AM phone cover photo"/>
                    </ImgContainerStyled>
                    <BackLogoContainerStyled>
                        <a href = "/restaurants">
                            <img src = "/arrm-back-icon-blue.png" alt = "al rayyan restaurant management go back icon"/>
                        </a>
                    </BackLogoContainerStyled>
                </MainContainerStyled>
            </>
        )
    }
}

export default HeaderFive;