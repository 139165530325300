import styled from "styled-components";
import {useState, useEffect} from "react";

import { MainContainerStyled } from "../RestaurantsCommonComps/HeaderStyled";


function ConceptSek({}) {
    const [isDesktop, setIsDesktop] = useState(true);

    function updateScreenSize() {
        if(window.innerWidth <= 500) setIsDesktop(false);
        else setIsDesktop(true);
        console.log(isDesktop);
    }

    useEffect(() => {
        window.addEventListener("resize", updateScreenSize);

        return () => {
            window.removeEventListener("resize", updateScreenSize);
        }
    })

    if(isDesktop) {
        return (
            <>
                <MainContainerStyled style = {{backgroundColor: "#00547B"}}>
                    <ConceptFlexBoxStyled>
                        <FlexBoxOneStyled>
                            <PageHeadingStyled>
                                <span>Concept</span>
                            </PageHeadingStyled>
                            <ParagraphContainerStyled>
                                <p>
                                    Shawarma El khebbez is a street food sandwiches concept that focuses
                                    on pickup and delivery expereinces, with exceptional choice of Carefully
                                    crafted shawarma sandwiches.
                                </p>
                            </ParagraphContainerStyled>
                            <ParagraphContainerStyled>
                                <p>
                                    Customers will experience a journey of flavours, from the still moist
                                    bread, juicy lean chicken, buttery arabic meat, complex vegan tastes with healthy
                                    vegetarian options.
                                </p>
                            </ParagraphContainerStyled>
                        </FlexBoxOneStyled>
                        <FlexBoxTwoStyled>
                            <img src = "/sek-designs/backgroundImgs/arrm-sek-concept-section-1.jpg" alt = "al rayyan restaurant management shawarma el khebbez restaurant concept page"/>
                        </FlexBoxTwoStyled>
                    </ConceptFlexBoxStyled>
                </MainContainerStyled>
            </>
        )
    }

    if(!isDesktop) {
        return (
            <>
            </>
        )
    }
}

const ConceptFlexBoxStyled = styled.div`
    display: flex;
    justify-content: center;
    color: #F1EFD9;
    padding: 0px 50px;
    gap: 50px;
   
`

const FlexBoxOneStyled = styled.div`
    position: relative;
    padding-top: 139px;
    padding-bottom: 250px;
    width: 50%;
`

const FlexBoxTwoStyled = styled.div`
    position: relative;

    

    img {
        width: 100%;
    }
`

const PageHeadingStyled = styled.div`
    position: relative;
    margin-bottom: 30px;
    font-weight: bold;
`

const ParagraphContainerStyled = styled.div`
    position: relative;
    margin-bottom: 22px;
    font-size: 18px;

    p {
        font-size: 18px;
       
    }
`

export default ConceptSek;